/* eslint-disable */


export interface GeoFilter {
  city: string;
  province: string;
  region: string;
  country: string;
}

export interface TimeFilter {
  rAfter: string;
  rBefore: string;
  afterMs: string;
  beforeMs: string;
}

export interface GetSentimentRequest {
  /**
   *  date filters
   */
  rAfter: string;
  rBefore: string;
  afterMs: string;
  beforeMs: string;
  /**
   *  unique eventID to get sentiment
   */
  eventId: string;
  /**
   *  topics tags on which to return sentiment
   */
  topic: string;
  /**
   *  personas of sentiment
   */
  persona: string;
  /**
   *  convo
   */
  convoId: string;
  geoFilter?: GeoFilter;
}

export interface GetSentimentResponse {
  values: SentimentValue[];
  /**
   *  filterable fields  */
  eventId: string;
  convoId: string;
  topic: string;
  persona: string;
}

export interface SentimentValue {
  /**
   *  YYYYMMDDHH
   */
  aggKey: string;
  value: number;
}

export interface CreateTopicRequest {
  name: string;
}

export interface CreateUserRequest {
  email: string;
  phone: string;
}

export interface CreateUseResponse {
  id: string;
  nickname: string;
}

export interface CreateUserMaskRequest {
  user: string;
  nickname: string;
  /**
   *  if nickName is not specified,
   *  we use the same nickName but
   *  generate a new mask
   */
  rotate: boolean;
}

export interface CreateReactionRequest {
  user: string;
  convTopic: string;
  convPersona: string;
  persona: string;
  /**
   *  TODO: restrict this by generating protobuf?
   */
  targetType: string;
  targetVal: string;
  reactionType: string;
  reactionVal: string;
  targetThreadId: string;
  city: string;
  countryCode: string;
}

export interface CreateMailboxRequest {
  user: string;
  conversation: string;
}

export interface CreatePersonaRequest {
  name: string;
}

export interface CreatePmailboxRequest {
  persona: string;
  conversation: string;
}

export interface CreateConvoLiteRequest {
  user: string;
  persona: string;
  verb: string;
  object: string;
  city: string;
  countryCode: string;
  id: string;
}

export interface CreateConversationRequest {
  user: string;
  topic: string;
  persona: string;
  text: string;
  /**
   *  TODO: restrict this by generating protobuf?
   */
  category: string;
  parentId: string;
  threadId: string;
  targetPersona: string;
  userNickname: string;
  city: string;
  countryCode: string;
  convoLite?: QuotedConvoLite;
  userMaskId: string;
  eventId: string;
  id: string;
  title: string;
  personaVerified: boolean;
  remoteIp: string;
}

export interface AddUserPersonaRequest {
  user: string;
  persona: string;
  verifyEmail: string;
}

export interface QuotedConvoLite {
  persona: string;
  verb: string;
  object: string;
  interval: Interval;
}

export interface CreateEventRequest {
  topic: string;
  title: string;
  descriptionShort: string;
  eventTime: string;
  descriptionLong: string;
  sourceLink: string;
  url: string;
}

export interface CreateEventResponse {
}

export interface ApiOption {
  geoFilter?: GeoFilter;
  timeFilter?: TimeFilter;
}

const baseGeoFilter: object = {
  city: "",
  province: "",
  region: "",
  country: "",
};

const baseTimeFilter: object = {
  rAfter: "",
  rBefore: "",
  afterMs: "",
  beforeMs: "",
};

const baseGetSentimentRequest: object = {
  rAfter: "",
  rBefore: "",
  afterMs: "",
  beforeMs: "",
  eventId: "",
  topic: "",
  persona: "",
  convoId: "",
};

const baseGetSentimentResponse: object = {
  eventId: "",
  convoId: "",
  topic: "",
  persona: "",
};

const baseSentimentValue: object = {
  aggKey: "",
  value: 0,
};

const baseCreateTopicRequest: object = {
  name: "",
};

const baseCreateUserRequest: object = {
  email: "",
  phone: "",
};

const baseCreateUseResponse: object = {
  id: "",
  nickname: "",
};

const baseCreateUserMaskRequest: object = {
  user: "",
  nickname: "",
  rotate: false,
};

const baseCreateReactionRequest: object = {
  user: "",
  convTopic: "",
  convPersona: "",
  persona: "",
  targetType: "",
  targetVal: "",
  reactionType: "",
  reactionVal: "",
  targetThreadId: "0",
  city: "",
  countryCode: "",
};

const baseCreateMailboxRequest: object = {
  user: "",
  conversation: "",
};

const baseCreatePersonaRequest: object = {
  name: "",
};

const baseCreatePmailboxRequest: object = {
  persona: "",
  conversation: "",
};

const baseCreateConvoLiteRequest: object = {
  user: "",
  persona: "",
  verb: "",
  object: "",
  city: "",
  countryCode: "",
  id: "",
};

const baseCreateConversationRequest: object = {
  user: "",
  topic: "",
  persona: "",
  text: "",
  category: "",
  parentId: "",
  threadId: "",
  targetPersona: "",
  userNickname: "",
  city: "",
  countryCode: "",
  userMaskId: "",
  eventId: "",
  id: "",
  title: "",
  personaVerified: false,
  remoteIp: "",
};

const baseAddUserPersonaRequest: object = {
  user: "",
  persona: "",
  verifyEmail: "",
};

const baseQuotedConvoLite: object = {
  persona: "",
  verb: "",
  object: "",
  interval: 0,
};

const baseCreateEventRequest: object = {
  topic: "",
  title: "",
  descriptionShort: "",
  eventTime: "0",
  descriptionLong: "",
  sourceLink: "",
  url: "",
};

const baseCreateEventResponse: object = {
};

const baseApiOption: object = {
};

export const protobufPackage = 'joey'

export enum Interval {
  HOUR = 0,
  DAY = 1,
  WEEK = 2,
  TWO_WEEK = 3,
  UNRECOGNIZED = -1,
}

export function intervalFromJSON(object: any): Interval {
  switch (object) {
    case 0:
    case "HOUR":
      return Interval.HOUR;
    case 1:
    case "DAY":
      return Interval.DAY;
    case 2:
    case "WEEK":
      return Interval.WEEK;
    case 3:
    case "TWO_WEEK":
      return Interval.TWO_WEEK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Interval.UNRECOGNIZED;
  }
}

export function intervalToJSON(object: Interval): string {
  switch (object) {
    case Interval.HOUR:
      return "HOUR";
    case Interval.DAY:
      return "DAY";
    case Interval.WEEK:
      return "WEEK";
    case Interval.TWO_WEEK:
      return "TWO_WEEK";
    default:
      return "UNKNOWN";
  }
}

export const GeoFilter = {
  fromJSON(object: any): GeoFilter {
    const message = { ...baseGeoFilter } as GeoFilter;
    if (object.city !== undefined && object.city !== null) {
      message.city = String(object.city);
    } else {
      message.city = "";
    }
    if (object.province !== undefined && object.province !== null) {
      message.province = String(object.province);
    } else {
      message.province = "";
    }
    if (object.region !== undefined && object.region !== null) {
      message.region = String(object.region);
    } else {
      message.region = "";
    }
    if (object.country !== undefined && object.country !== null) {
      message.country = String(object.country);
    } else {
      message.country = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<GeoFilter>): GeoFilter {
    const message = { ...baseGeoFilter } as GeoFilter;
    if (object.city !== undefined && object.city !== null) {
      message.city = object.city;
    } else {
      message.city = "";
    }
    if (object.province !== undefined && object.province !== null) {
      message.province = object.province;
    } else {
      message.province = "";
    }
    if (object.region !== undefined && object.region !== null) {
      message.region = object.region;
    } else {
      message.region = "";
    }
    if (object.country !== undefined && object.country !== null) {
      message.country = object.country;
    } else {
      message.country = "";
    }
    return message;
  },
  toJSON(message: GeoFilter): unknown {
    const obj: any = {};
    message.city !== undefined && (obj.city = message.city);
    message.province !== undefined && (obj.province = message.province);
    message.region !== undefined && (obj.region = message.region);
    message.country !== undefined && (obj.country = message.country);
    return obj;
  },
};

export const TimeFilter = {
  fromJSON(object: any): TimeFilter {
    const message = { ...baseTimeFilter } as TimeFilter;
    if (object.rAfter !== undefined && object.rAfter !== null) {
      message.rAfter = String(object.rAfter);
    } else {
      message.rAfter = "";
    }
    if (object.rBefore !== undefined && object.rBefore !== null) {
      message.rBefore = String(object.rBefore);
    } else {
      message.rBefore = "";
    }
    if (object.afterMs !== undefined && object.afterMs !== null) {
      message.afterMs = String(object.afterMs);
    } else {
      message.afterMs = "";
    }
    if (object.beforeMs !== undefined && object.beforeMs !== null) {
      message.beforeMs = String(object.beforeMs);
    } else {
      message.beforeMs = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<TimeFilter>): TimeFilter {
    const message = { ...baseTimeFilter } as TimeFilter;
    if (object.rAfter !== undefined && object.rAfter !== null) {
      message.rAfter = object.rAfter;
    } else {
      message.rAfter = "";
    }
    if (object.rBefore !== undefined && object.rBefore !== null) {
      message.rBefore = object.rBefore;
    } else {
      message.rBefore = "";
    }
    if (object.afterMs !== undefined && object.afterMs !== null) {
      message.afterMs = object.afterMs;
    } else {
      message.afterMs = "";
    }
    if (object.beforeMs !== undefined && object.beforeMs !== null) {
      message.beforeMs = object.beforeMs;
    } else {
      message.beforeMs = "";
    }
    return message;
  },
  toJSON(message: TimeFilter): unknown {
    const obj: any = {};
    message.rAfter !== undefined && (obj.rAfter = message.rAfter);
    message.rBefore !== undefined && (obj.rBefore = message.rBefore);
    message.afterMs !== undefined && (obj.afterMs = message.afterMs);
    message.beforeMs !== undefined && (obj.beforeMs = message.beforeMs);
    return obj;
  },
};

export const GetSentimentRequest = {
  fromJSON(object: any): GetSentimentRequest {
    const message = { ...baseGetSentimentRequest } as GetSentimentRequest;
    if (object.rAfter !== undefined && object.rAfter !== null) {
      message.rAfter = String(object.rAfter);
    } else {
      message.rAfter = "";
    }
    if (object.rBefore !== undefined && object.rBefore !== null) {
      message.rBefore = String(object.rBefore);
    } else {
      message.rBefore = "";
    }
    if (object.afterMs !== undefined && object.afterMs !== null) {
      message.afterMs = String(object.afterMs);
    } else {
      message.afterMs = "";
    }
    if (object.beforeMs !== undefined && object.beforeMs !== null) {
      message.beforeMs = String(object.beforeMs);
    } else {
      message.beforeMs = "";
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = "";
    }
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = String(object.topic);
    } else {
      message.topic = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.convoId !== undefined && object.convoId !== null) {
      message.convoId = String(object.convoId);
    } else {
      message.convoId = "";
    }
    if (object.geoFilter !== undefined && object.geoFilter !== null) {
      message.geoFilter = GeoFilter.fromJSON(object.geoFilter);
    } else {
      message.geoFilter = undefined;
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSentimentRequest>): GetSentimentRequest {
    const message = { ...baseGetSentimentRequest } as GetSentimentRequest;
    if (object.rAfter !== undefined && object.rAfter !== null) {
      message.rAfter = object.rAfter;
    } else {
      message.rAfter = "";
    }
    if (object.rBefore !== undefined && object.rBefore !== null) {
      message.rBefore = object.rBefore;
    } else {
      message.rBefore = "";
    }
    if (object.afterMs !== undefined && object.afterMs !== null) {
      message.afterMs = object.afterMs;
    } else {
      message.afterMs = "";
    }
    if (object.beforeMs !== undefined && object.beforeMs !== null) {
      message.beforeMs = object.beforeMs;
    } else {
      message.beforeMs = "";
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = "";
    }
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = object.topic;
    } else {
      message.topic = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.convoId !== undefined && object.convoId !== null) {
      message.convoId = object.convoId;
    } else {
      message.convoId = "";
    }
    if (object.geoFilter !== undefined && object.geoFilter !== null) {
      message.geoFilter = GeoFilter.fromPartial(object.geoFilter);
    } else {
      message.geoFilter = undefined;
    }
    return message;
  },
  toJSON(message: GetSentimentRequest): unknown {
    const obj: any = {};
    message.rAfter !== undefined && (obj.rAfter = message.rAfter);
    message.rBefore !== undefined && (obj.rBefore = message.rBefore);
    message.afterMs !== undefined && (obj.afterMs = message.afterMs);
    message.beforeMs !== undefined && (obj.beforeMs = message.beforeMs);
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.topic !== undefined && (obj.topic = message.topic);
    message.persona !== undefined && (obj.persona = message.persona);
    message.convoId !== undefined && (obj.convoId = message.convoId);
    message.geoFilter !== undefined && (obj.geoFilter = message.geoFilter ? GeoFilter.toJSON(message.geoFilter) : undefined);
    return obj;
  },
};

export const GetSentimentResponse = {
  fromJSON(object: any): GetSentimentResponse {
    const message = { ...baseGetSentimentResponse } as GetSentimentResponse;
    message.values = [];
    if (object.values !== undefined && object.values !== null) {
      for (const e of object.values) {
        message.values.push(SentimentValue.fromJSON(e));
      }
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = "";
    }
    if (object.convoId !== undefined && object.convoId !== null) {
      message.convoId = String(object.convoId);
    } else {
      message.convoId = "";
    }
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = String(object.topic);
    } else {
      message.topic = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSentimentResponse>): GetSentimentResponse {
    const message = { ...baseGetSentimentResponse } as GetSentimentResponse;
    message.values = [];
    if (object.values !== undefined && object.values !== null) {
      for (const e of object.values) {
        message.values.push(SentimentValue.fromPartial(e));
      }
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = "";
    }
    if (object.convoId !== undefined && object.convoId !== null) {
      message.convoId = object.convoId;
    } else {
      message.convoId = "";
    }
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = object.topic;
    } else {
      message.topic = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    return message;
  },
  toJSON(message: GetSentimentResponse): unknown {
    const obj: any = {};
    if (message.values) {
      obj.values = message.values.map(e => e ? SentimentValue.toJSON(e) : undefined);
    } else {
      obj.values = [];
    }
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.convoId !== undefined && (obj.convoId = message.convoId);
    message.topic !== undefined && (obj.topic = message.topic);
    message.persona !== undefined && (obj.persona = message.persona);
    return obj;
  },
};

export const SentimentValue = {
  fromJSON(object: any): SentimentValue {
    const message = { ...baseSentimentValue } as SentimentValue;
    if (object.aggKey !== undefined && object.aggKey !== null) {
      message.aggKey = String(object.aggKey);
    } else {
      message.aggKey = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = Number(object.value);
    } else {
      message.value = 0;
    }
    return message;
  },
  fromPartial(object: DeepPartial<SentimentValue>): SentimentValue {
    const message = { ...baseSentimentValue } as SentimentValue;
    if (object.aggKey !== undefined && object.aggKey !== null) {
      message.aggKey = object.aggKey;
    } else {
      message.aggKey = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = 0;
    }
    return message;
  },
  toJSON(message: SentimentValue): unknown {
    const obj: any = {};
    message.aggKey !== undefined && (obj.aggKey = message.aggKey);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },
};

export const CreateTopicRequest = {
  fromJSON(object: any): CreateTopicRequest {
    const message = { ...baseCreateTopicRequest } as CreateTopicRequest;
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateTopicRequest>): CreateTopicRequest {
    const message = { ...baseCreateTopicRequest } as CreateTopicRequest;
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = "";
    }
    return message;
  },
  toJSON(message: CreateTopicRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },
};

export const CreateUserRequest = {
  fromJSON(object: any): CreateUserRequest {
    const message = { ...baseCreateUserRequest } as CreateUserRequest;
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = String(object.phone);
    } else {
      message.phone = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateUserRequest>): CreateUserRequest {
    const message = { ...baseCreateUserRequest } as CreateUserRequest;
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = object.phone;
    } else {
      message.phone = "";
    }
    return message;
  },
  toJSON(message: CreateUserRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    return obj;
  },
};

export const CreateUseResponse = {
  fromJSON(object: any): CreateUseResponse {
    const message = { ...baseCreateUseResponse } as CreateUseResponse;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    if (object.nickname !== undefined && object.nickname !== null) {
      message.nickname = String(object.nickname);
    } else {
      message.nickname = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateUseResponse>): CreateUseResponse {
    const message = { ...baseCreateUseResponse } as CreateUseResponse;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    if (object.nickname !== undefined && object.nickname !== null) {
      message.nickname = object.nickname;
    } else {
      message.nickname = "";
    }
    return message;
  },
  toJSON(message: CreateUseResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.nickname !== undefined && (obj.nickname = message.nickname);
    return obj;
  },
};

export const CreateUserMaskRequest = {
  fromJSON(object: any): CreateUserMaskRequest {
    const message = { ...baseCreateUserMaskRequest } as CreateUserMaskRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = String(object.user);
    } else {
      message.user = "";
    }
    if (object.nickname !== undefined && object.nickname !== null) {
      message.nickname = String(object.nickname);
    } else {
      message.nickname = "";
    }
    if (object.rotate !== undefined && object.rotate !== null) {
      message.rotate = Boolean(object.rotate);
    } else {
      message.rotate = false;
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateUserMaskRequest>): CreateUserMaskRequest {
    const message = { ...baseCreateUserMaskRequest } as CreateUserMaskRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = object.user;
    } else {
      message.user = "";
    }
    if (object.nickname !== undefined && object.nickname !== null) {
      message.nickname = object.nickname;
    } else {
      message.nickname = "";
    }
    if (object.rotate !== undefined && object.rotate !== null) {
      message.rotate = object.rotate;
    } else {
      message.rotate = false;
    }
    return message;
  },
  toJSON(message: CreateUserMaskRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.nickname !== undefined && (obj.nickname = message.nickname);
    message.rotate !== undefined && (obj.rotate = message.rotate);
    return obj;
  },
};

export const CreateReactionRequest = {
  fromJSON(object: any): CreateReactionRequest {
    const message = { ...baseCreateReactionRequest } as CreateReactionRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = String(object.user);
    } else {
      message.user = "";
    }
    if (object.convTopic !== undefined && object.convTopic !== null) {
      message.convTopic = String(object.convTopic);
    } else {
      message.convTopic = "";
    }
    if (object.convPersona !== undefined && object.convPersona !== null) {
      message.convPersona = String(object.convPersona);
    } else {
      message.convPersona = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.targetType !== undefined && object.targetType !== null) {
      message.targetType = String(object.targetType);
    } else {
      message.targetType = "";
    }
    if (object.targetVal !== undefined && object.targetVal !== null) {
      message.targetVal = String(object.targetVal);
    } else {
      message.targetVal = "";
    }
    if (object.reactionType !== undefined && object.reactionType !== null) {
      message.reactionType = String(object.reactionType);
    } else {
      message.reactionType = "";
    }
    if (object.reactionVal !== undefined && object.reactionVal !== null) {
      message.reactionVal = String(object.reactionVal);
    } else {
      message.reactionVal = "";
    }
    if (object.targetThreadId !== undefined && object.targetThreadId !== null) {
      message.targetThreadId = String(object.targetThreadId);
    } else {
      message.targetThreadId = "0";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = String(object.city);
    } else {
      message.city = "";
    }
    if (object.countryCode !== undefined && object.countryCode !== null) {
      message.countryCode = String(object.countryCode);
    } else {
      message.countryCode = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateReactionRequest>): CreateReactionRequest {
    const message = { ...baseCreateReactionRequest } as CreateReactionRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = object.user;
    } else {
      message.user = "";
    }
    if (object.convTopic !== undefined && object.convTopic !== null) {
      message.convTopic = object.convTopic;
    } else {
      message.convTopic = "";
    }
    if (object.convPersona !== undefined && object.convPersona !== null) {
      message.convPersona = object.convPersona;
    } else {
      message.convPersona = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.targetType !== undefined && object.targetType !== null) {
      message.targetType = object.targetType;
    } else {
      message.targetType = "";
    }
    if (object.targetVal !== undefined && object.targetVal !== null) {
      message.targetVal = object.targetVal;
    } else {
      message.targetVal = "";
    }
    if (object.reactionType !== undefined && object.reactionType !== null) {
      message.reactionType = object.reactionType;
    } else {
      message.reactionType = "";
    }
    if (object.reactionVal !== undefined && object.reactionVal !== null) {
      message.reactionVal = object.reactionVal;
    } else {
      message.reactionVal = "";
    }
    if (object.targetThreadId !== undefined && object.targetThreadId !== null) {
      message.targetThreadId = object.targetThreadId;
    } else {
      message.targetThreadId = "0";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = object.city;
    } else {
      message.city = "";
    }
    if (object.countryCode !== undefined && object.countryCode !== null) {
      message.countryCode = object.countryCode;
    } else {
      message.countryCode = "";
    }
    return message;
  },
  toJSON(message: CreateReactionRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.convTopic !== undefined && (obj.convTopic = message.convTopic);
    message.convPersona !== undefined && (obj.convPersona = message.convPersona);
    message.persona !== undefined && (obj.persona = message.persona);
    message.targetType !== undefined && (obj.targetType = message.targetType);
    message.targetVal !== undefined && (obj.targetVal = message.targetVal);
    message.reactionType !== undefined && (obj.reactionType = message.reactionType);
    message.reactionVal !== undefined && (obj.reactionVal = message.reactionVal);
    message.targetThreadId !== undefined && (obj.targetThreadId = message.targetThreadId);
    message.city !== undefined && (obj.city = message.city);
    message.countryCode !== undefined && (obj.countryCode = message.countryCode);
    return obj;
  },
};

export const CreateMailboxRequest = {
  fromJSON(object: any): CreateMailboxRequest {
    const message = { ...baseCreateMailboxRequest } as CreateMailboxRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = String(object.user);
    } else {
      message.user = "";
    }
    if (object.conversation !== undefined && object.conversation !== null) {
      message.conversation = String(object.conversation);
    } else {
      message.conversation = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateMailboxRequest>): CreateMailboxRequest {
    const message = { ...baseCreateMailboxRequest } as CreateMailboxRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = object.user;
    } else {
      message.user = "";
    }
    if (object.conversation !== undefined && object.conversation !== null) {
      message.conversation = object.conversation;
    } else {
      message.conversation = "";
    }
    return message;
  },
  toJSON(message: CreateMailboxRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.conversation !== undefined && (obj.conversation = message.conversation);
    return obj;
  },
};

export const CreatePersonaRequest = {
  fromJSON(object: any): CreatePersonaRequest {
    const message = { ...baseCreatePersonaRequest } as CreatePersonaRequest;
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreatePersonaRequest>): CreatePersonaRequest {
    const message = { ...baseCreatePersonaRequest } as CreatePersonaRequest;
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = "";
    }
    return message;
  },
  toJSON(message: CreatePersonaRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },
};

export const CreatePmailboxRequest = {
  fromJSON(object: any): CreatePmailboxRequest {
    const message = { ...baseCreatePmailboxRequest } as CreatePmailboxRequest;
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.conversation !== undefined && object.conversation !== null) {
      message.conversation = String(object.conversation);
    } else {
      message.conversation = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreatePmailboxRequest>): CreatePmailboxRequest {
    const message = { ...baseCreatePmailboxRequest } as CreatePmailboxRequest;
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.conversation !== undefined && object.conversation !== null) {
      message.conversation = object.conversation;
    } else {
      message.conversation = "";
    }
    return message;
  },
  toJSON(message: CreatePmailboxRequest): unknown {
    const obj: any = {};
    message.persona !== undefined && (obj.persona = message.persona);
    message.conversation !== undefined && (obj.conversation = message.conversation);
    return obj;
  },
};

export const CreateConvoLiteRequest = {
  fromJSON(object: any): CreateConvoLiteRequest {
    const message = { ...baseCreateConvoLiteRequest } as CreateConvoLiteRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = String(object.user);
    } else {
      message.user = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.verb !== undefined && object.verb !== null) {
      message.verb = String(object.verb);
    } else {
      message.verb = "";
    }
    if (object.object !== undefined && object.object !== null) {
      message.object = String(object.object);
    } else {
      message.object = "";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = String(object.city);
    } else {
      message.city = "";
    }
    if (object.countryCode !== undefined && object.countryCode !== null) {
      message.countryCode = String(object.countryCode);
    } else {
      message.countryCode = "";
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateConvoLiteRequest>): CreateConvoLiteRequest {
    const message = { ...baseCreateConvoLiteRequest } as CreateConvoLiteRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = object.user;
    } else {
      message.user = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.verb !== undefined && object.verb !== null) {
      message.verb = object.verb;
    } else {
      message.verb = "";
    }
    if (object.object !== undefined && object.object !== null) {
      message.object = object.object;
    } else {
      message.object = "";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = object.city;
    } else {
      message.city = "";
    }
    if (object.countryCode !== undefined && object.countryCode !== null) {
      message.countryCode = object.countryCode;
    } else {
      message.countryCode = "";
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    return message;
  },
  toJSON(message: CreateConvoLiteRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.persona !== undefined && (obj.persona = message.persona);
    message.verb !== undefined && (obj.verb = message.verb);
    message.object !== undefined && (obj.object = message.object);
    message.city !== undefined && (obj.city = message.city);
    message.countryCode !== undefined && (obj.countryCode = message.countryCode);
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },
};

export const CreateConversationRequest = {
  fromJSON(object: any): CreateConversationRequest {
    const message = { ...baseCreateConversationRequest } as CreateConversationRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = String(object.user);
    } else {
      message.user = "";
    }
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = String(object.topic);
    } else {
      message.topic = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.text !== undefined && object.text !== null) {
      message.text = String(object.text);
    } else {
      message.text = "";
    }
    if (object.category !== undefined && object.category !== null) {
      message.category = String(object.category);
    } else {
      message.category = "";
    }
    if (object.parentId !== undefined && object.parentId !== null) {
      message.parentId = String(object.parentId);
    } else {
      message.parentId = "";
    }
    if (object.threadId !== undefined && object.threadId !== null) {
      message.threadId = String(object.threadId);
    } else {
      message.threadId = "";
    }
    if (object.targetPersona !== undefined && object.targetPersona !== null) {
      message.targetPersona = String(object.targetPersona);
    } else {
      message.targetPersona = "";
    }
    if (object.userNickname !== undefined && object.userNickname !== null) {
      message.userNickname = String(object.userNickname);
    } else {
      message.userNickname = "";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = String(object.city);
    } else {
      message.city = "";
    }
    if (object.countryCode !== undefined && object.countryCode !== null) {
      message.countryCode = String(object.countryCode);
    } else {
      message.countryCode = "";
    }
    if (object.convoLite !== undefined && object.convoLite !== null) {
      message.convoLite = QuotedConvoLite.fromJSON(object.convoLite);
    } else {
      message.convoLite = undefined;
    }
    if (object.userMaskId !== undefined && object.userMaskId !== null) {
      message.userMaskId = String(object.userMaskId);
    } else {
      message.userMaskId = "";
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = "";
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    if (object.personaVerified !== undefined && object.personaVerified !== null) {
      message.personaVerified = Boolean(object.personaVerified);
    } else {
      message.personaVerified = false;
    }
    if (object.remoteIp !== undefined && object.remoteIp !== null) {
      message.remoteIp = String(object.remoteIp);
    } else {
      message.remoteIp = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateConversationRequest>): CreateConversationRequest {
    const message = { ...baseCreateConversationRequest } as CreateConversationRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = object.user;
    } else {
      message.user = "";
    }
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = object.topic;
    } else {
      message.topic = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.text !== undefined && object.text !== null) {
      message.text = object.text;
    } else {
      message.text = "";
    }
    if (object.category !== undefined && object.category !== null) {
      message.category = object.category;
    } else {
      message.category = "";
    }
    if (object.parentId !== undefined && object.parentId !== null) {
      message.parentId = object.parentId;
    } else {
      message.parentId = "";
    }
    if (object.threadId !== undefined && object.threadId !== null) {
      message.threadId = object.threadId;
    } else {
      message.threadId = "";
    }
    if (object.targetPersona !== undefined && object.targetPersona !== null) {
      message.targetPersona = object.targetPersona;
    } else {
      message.targetPersona = "";
    }
    if (object.userNickname !== undefined && object.userNickname !== null) {
      message.userNickname = object.userNickname;
    } else {
      message.userNickname = "";
    }
    if (object.city !== undefined && object.city !== null) {
      message.city = object.city;
    } else {
      message.city = "";
    }
    if (object.countryCode !== undefined && object.countryCode !== null) {
      message.countryCode = object.countryCode;
    } else {
      message.countryCode = "";
    }
    if (object.convoLite !== undefined && object.convoLite !== null) {
      message.convoLite = QuotedConvoLite.fromPartial(object.convoLite);
    } else {
      message.convoLite = undefined;
    }
    if (object.userMaskId !== undefined && object.userMaskId !== null) {
      message.userMaskId = object.userMaskId;
    } else {
      message.userMaskId = "";
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = "";
    }
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    if (object.personaVerified !== undefined && object.personaVerified !== null) {
      message.personaVerified = object.personaVerified;
    } else {
      message.personaVerified = false;
    }
    if (object.remoteIp !== undefined && object.remoteIp !== null) {
      message.remoteIp = object.remoteIp;
    } else {
      message.remoteIp = "";
    }
    return message;
  },
  toJSON(message: CreateConversationRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.topic !== undefined && (obj.topic = message.topic);
    message.persona !== undefined && (obj.persona = message.persona);
    message.text !== undefined && (obj.text = message.text);
    message.category !== undefined && (obj.category = message.category);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.threadId !== undefined && (obj.threadId = message.threadId);
    message.targetPersona !== undefined && (obj.targetPersona = message.targetPersona);
    message.userNickname !== undefined && (obj.userNickname = message.userNickname);
    message.city !== undefined && (obj.city = message.city);
    message.countryCode !== undefined && (obj.countryCode = message.countryCode);
    message.convoLite !== undefined && (obj.convoLite = message.convoLite ? QuotedConvoLite.toJSON(message.convoLite) : undefined);
    message.userMaskId !== undefined && (obj.userMaskId = message.userMaskId);
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.personaVerified !== undefined && (obj.personaVerified = message.personaVerified);
    message.remoteIp !== undefined && (obj.remoteIp = message.remoteIp);
    return obj;
  },
};

export const AddUserPersonaRequest = {
  fromJSON(object: any): AddUserPersonaRequest {
    const message = { ...baseAddUserPersonaRequest } as AddUserPersonaRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = String(object.user);
    } else {
      message.user = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.verifyEmail !== undefined && object.verifyEmail !== null) {
      message.verifyEmail = String(object.verifyEmail);
    } else {
      message.verifyEmail = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<AddUserPersonaRequest>): AddUserPersonaRequest {
    const message = { ...baseAddUserPersonaRequest } as AddUserPersonaRequest;
    if (object.user !== undefined && object.user !== null) {
      message.user = object.user;
    } else {
      message.user = "";
    }
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.verifyEmail !== undefined && object.verifyEmail !== null) {
      message.verifyEmail = object.verifyEmail;
    } else {
      message.verifyEmail = "";
    }
    return message;
  },
  toJSON(message: AddUserPersonaRequest): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.persona !== undefined && (obj.persona = message.persona);
    message.verifyEmail !== undefined && (obj.verifyEmail = message.verifyEmail);
    return obj;
  },
};

export const QuotedConvoLite = {
  fromJSON(object: any): QuotedConvoLite {
    const message = { ...baseQuotedConvoLite } as QuotedConvoLite;
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = String(object.persona);
    } else {
      message.persona = "";
    }
    if (object.verb !== undefined && object.verb !== null) {
      message.verb = String(object.verb);
    } else {
      message.verb = "";
    }
    if (object.object !== undefined && object.object !== null) {
      message.object = String(object.object);
    } else {
      message.object = "";
    }
    if (object.interval !== undefined && object.interval !== null) {
      message.interval = intervalFromJSON(object.interval);
    } else {
      message.interval = 0;
    }
    return message;
  },
  fromPartial(object: DeepPartial<QuotedConvoLite>): QuotedConvoLite {
    const message = { ...baseQuotedConvoLite } as QuotedConvoLite;
    if (object.persona !== undefined && object.persona !== null) {
      message.persona = object.persona;
    } else {
      message.persona = "";
    }
    if (object.verb !== undefined && object.verb !== null) {
      message.verb = object.verb;
    } else {
      message.verb = "";
    }
    if (object.object !== undefined && object.object !== null) {
      message.object = object.object;
    } else {
      message.object = "";
    }
    if (object.interval !== undefined && object.interval !== null) {
      message.interval = object.interval;
    } else {
      message.interval = 0;
    }
    return message;
  },
  toJSON(message: QuotedConvoLite): unknown {
    const obj: any = {};
    message.persona !== undefined && (obj.persona = message.persona);
    message.verb !== undefined && (obj.verb = message.verb);
    message.object !== undefined && (obj.object = message.object);
    message.interval !== undefined && (obj.interval = intervalToJSON(message.interval));
    return obj;
  },
};

export const CreateEventRequest = {
  fromJSON(object: any): CreateEventRequest {
    const message = { ...baseCreateEventRequest } as CreateEventRequest;
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = String(object.topic);
    } else {
      message.topic = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    if (object.descriptionShort !== undefined && object.descriptionShort !== null) {
      message.descriptionShort = String(object.descriptionShort);
    } else {
      message.descriptionShort = "";
    }
    if (object.eventTime !== undefined && object.eventTime !== null) {
      message.eventTime = String(object.eventTime);
    } else {
      message.eventTime = "0";
    }
    if (object.descriptionLong !== undefined && object.descriptionLong !== null) {
      message.descriptionLong = String(object.descriptionLong);
    } else {
      message.descriptionLong = "";
    }
    if (object.sourceLink !== undefined && object.sourceLink !== null) {
      message.sourceLink = String(object.sourceLink);
    } else {
      message.sourceLink = "";
    }
    if (object.url !== undefined && object.url !== null) {
      message.url = String(object.url);
    } else {
      message.url = "";
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateEventRequest>): CreateEventRequest {
    const message = { ...baseCreateEventRequest } as CreateEventRequest;
    if (object.topic !== undefined && object.topic !== null) {
      message.topic = object.topic;
    } else {
      message.topic = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    if (object.descriptionShort !== undefined && object.descriptionShort !== null) {
      message.descriptionShort = object.descriptionShort;
    } else {
      message.descriptionShort = "";
    }
    if (object.eventTime !== undefined && object.eventTime !== null) {
      message.eventTime = object.eventTime;
    } else {
      message.eventTime = "0";
    }
    if (object.descriptionLong !== undefined && object.descriptionLong !== null) {
      message.descriptionLong = object.descriptionLong;
    } else {
      message.descriptionLong = "";
    }
    if (object.sourceLink !== undefined && object.sourceLink !== null) {
      message.sourceLink = object.sourceLink;
    } else {
      message.sourceLink = "";
    }
    if (object.url !== undefined && object.url !== null) {
      message.url = object.url;
    } else {
      message.url = "";
    }
    return message;
  },
  toJSON(message: CreateEventRequest): unknown {
    const obj: any = {};
    message.topic !== undefined && (obj.topic = message.topic);
    message.title !== undefined && (obj.title = message.title);
    message.descriptionShort !== undefined && (obj.descriptionShort = message.descriptionShort);
    message.eventTime !== undefined && (obj.eventTime = message.eventTime);
    message.descriptionLong !== undefined && (obj.descriptionLong = message.descriptionLong);
    message.sourceLink !== undefined && (obj.sourceLink = message.sourceLink);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },
};

export const CreateEventResponse = {
  fromJSON(_: any): CreateEventResponse {
    const message = { ...baseCreateEventResponse } as CreateEventResponse;
    return message;
  },
  fromPartial(_: DeepPartial<CreateEventResponse>): CreateEventResponse {
    const message = { ...baseCreateEventResponse } as CreateEventResponse;
    return message;
  },
  toJSON(_: CreateEventResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ApiOption = {
  fromJSON(object: any): ApiOption {
    const message = { ...baseApiOption } as ApiOption;
    if (object.geoFilter !== undefined && object.geoFilter !== null) {
      message.geoFilter = GeoFilter.fromJSON(object.geoFilter);
    } else {
      message.geoFilter = undefined;
    }
    if (object.timeFilter !== undefined && object.timeFilter !== null) {
      message.timeFilter = TimeFilter.fromJSON(object.timeFilter);
    } else {
      message.timeFilter = undefined;
    }
    return message;
  },
  fromPartial(object: DeepPartial<ApiOption>): ApiOption {
    const message = { ...baseApiOption } as ApiOption;
    if (object.geoFilter !== undefined && object.geoFilter !== null) {
      message.geoFilter = GeoFilter.fromPartial(object.geoFilter);
    } else {
      message.geoFilter = undefined;
    }
    if (object.timeFilter !== undefined && object.timeFilter !== null) {
      message.timeFilter = TimeFilter.fromPartial(object.timeFilter);
    } else {
      message.timeFilter = undefined;
    }
    return message;
  },
  toJSON(message: ApiOption): unknown {
    const obj: any = {};
    message.geoFilter !== undefined && (obj.geoFilter = message.geoFilter ? GeoFilter.toJSON(message.geoFilter) : undefined);
    message.timeFilter !== undefined && (obj.timeFilter = message.timeFilter ? TimeFilter.toJSON(message.timeFilter) : undefined);
    return obj;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;