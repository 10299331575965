import api from "@/api";
import fetcher from "@/fetcher";
import { Event } from "@/gen/schema";
import path from "@/server/path";
import Link from "next/link";
import React, { FunctionComponent as FC } from "react";
import useSWR from "swr";
import Err from "./Err";
import Loading from "./Loading";

interface EventHeaderProps {
  eventId: string;
  noLink?: boolean;
  children?: React.ReactElement;
}

const EventHeader: FC<EventHeaderProps> = (props: EventHeaderProps) => {
  const { data, isLoading } = useSWR<{ event: Event }>(
    api.event(props.eventId),
    fetcher
  );

  if (isLoading) {
    return <Loading textOnly></Loading>;
  }

  if (!data) {
    return <Err></Err>;
  }

  const { event } = data;

  const content = (
    <>
      <div className="hover:underline py-1 px-3 md:py-1 md:px-3 border border-gray-400 rounded-xl bg-white w-40 overflow-ellipsis overflow-hidden whitespace-nowrap text-black">
        <span className="font-semibold">{event.title}</span>
      </div>
    </>
  );

  if (props.noLink) {
    return content;
  }

  return (
    <Link passHref href={path.event(props.eventId)}>
      <a>{content}</a>
    </Link>
  );
};

export default EventHeader;
