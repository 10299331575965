import React, { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useBlurClick(
  refs: Array<React.RefObject<HTMLElement>>,
  cb: () => void
) {
  useEffect(() => {
    /**
     * trigger `cb` if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const validRefs = refs.filter((r) => r.current !== null);

      if (validRefs.length === 0) {
        return;
      }

      const targetIsChild =
        validRefs.filter((r) => {
          return r.current!.contains(event.target as any);
        }).length > 0;

      if (!targetIsChild) {
        cb();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, refs);
}
