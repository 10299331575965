import api from "@/api";
import fetcher, { post } from "@/fetcher";
import { Conversation, ReactionVal, TargetType } from "@/gen/schema";
import { useI18n } from "@/i18n/hook";
import enumKeys from "@/lib/enumKeys";
import reaction2Emoji from "@/lib/reaction2Emoji";
import { useSession } from "@/nextlib/context";
import { onStoreUpdate, trigger } from "@/store/store";
import { ReactionUI as ReactionUITy } from "@/store/ty";
import { useEffect, useState } from "react";
import useSWR from "swr";
import Loading from "./Loading";
import Modal from "./Modal";
import PersonaSelector2 from "./PersonaSelector2";

interface ReactionModalProps {}

export default function ReactionModal(props: ReactionModalProps) {
  const session = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [convo, setConvo] = useState<Conversation>();
  const i18n = useI18n();

  // TODO: not ideal we are loading this in `Modal` wrapper layer
  // and child layer but allows easier reuse of content without modal
  const { data, isValidating: loadingPersona } = useSWR(
    !!session ? api.account.persona : null,
    fetcher
  );

  useEffect(() => {
    const s = onStoreUpdate("$.ui.reactionUI", (res) => {
      const state = res.data[0] as ReactionUITy;
      setIsOpen(state?.isOpen ?? false);
      setConvo(state?.convo);
    });
    return () => {
      s.end(true);
    };
  }, []);

  async function _onClick(persona: string, reactionVal: ReactionVal) {
    try {
      await post(api.account.reaction(), {
        targetType: TargetType.conversation,
        targetVal: convo?.id?.toString(),
        targetThread: convo?.threadId?.toString(),
        convTopic: convo?.topic,
        convPersona: convo?.persona,
        reactionVal,
        persona,
      });

      // ORDER matters...
      trigger.ui.notificationMessage({
        message: i18n.t("post_success"),
      });
      setTimeout(
        () =>
          trigger.ui.reactionUIToggle({
            isOpen: false,
          }),
        0
      );
    } catch (err) {
      console.error(err);
      trigger.ui.notificationMessage({
        state: "error",
        message: i18n.t("error/network"),
      });
    }
  }

  const isLoading = loadingPersona;
  let show = true;
  if (!isLoading && !session?.email && isOpen) {
    show = false;
    trigger.ui.accountPrompt({ isOpen: true });
    setTimeout(() => {
      trigger.ui.reactionUIToggle({ isOpen: false });
    }, 0);
  } else if (!isLoading && isOpen && session?.email && !data?.result.length) {
    show = false;
    /** if we do not have any personas */
    trigger.ui.personaPrompt({ isOpen: true });
    setTimeout(() => {
      trigger.ui.reactionUIToggle({ isOpen: false });
    }, 0);
  }

  const loggedInWithPersonasAndOpen =
    !isLoading && isOpen && session?.email && data?.result.length;

  return (
    <Modal
      hidden={!loggedInWithPersonasAndOpen && show}
      onBgClick={() => trigger.ui.reactionUIToggle({ isOpen: false })}
    >
      {session ? <ReactionUI onClick={_onClick} /> : <></>}
    </Modal>
  );
}

interface ReactionUIProps {
  onClick: (persona: string, reactionVal: ReactionVal) => void;
}

export function ReactionUI(props: ReactionUIProps) {
  const [persona, setPersona] = useState("");
  const { data, isValidating } = useSWR(api.account.persona, fetcher);

  const i18n = useI18n();
  const rs = enumKeys(ReactionVal)
    // TODO: hardcoding the removal of `reply` & `comment` for now
    .slice(0, -2)
    .map((k) => {
      return (
        <li
          className="flex cursor-pointer rounded-full items-center justify-center border border-gray-300 h-12 w-12 sm:w-14 sm:h-14 my-1 shadow text-2xl select-none hover:bg-gray-200 active:bg-gray-400"
          key={k}
          data-reaction={k}
          onClick={() => props.onClick(persona, k as ReactionVal)}
        >
          <span>{reaction2Emoji(k as ReactionVal)} </span>
        </li>
      );
    });

  if (isValidating) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      <PersonaSelector2
        onSelect={setPersona}
        prompt={i18n.t("react_as")}
        personas={data?.result}
      />
      <div className="flex justify-center bg-white border border-gray-300 p-4 rounded-b-lg">
        <ul className="flex flex-wrap justify-around items-center gap-x-10 gap-y-5">
          {rs}
        </ul>
      </div>
    </div>
  );
}
