import api from "@/api";
import PersonaSelector2 from "@/comp/PersonaSelector2";
import { post } from "@/fetcher";
import { CreateConversationRequest } from "@/gen/api";
import { Category, Conversation } from "@/gen/schema";
import { useI18n } from "@/i18n/hook";
import category2Icon from "@/lib/category2Icon";
import { getLogger } from "@/log";
import { useSession } from "@/nextlib/context";
import { Session } from "@/nextlib/session";
import { trigger } from "@/store/store";
import React from "react";
import Convo from "./Convo";
import styles from "./Editor.module.scss";
import EventHeader from "./EventHeader";
import InputWithValidation from "./InputWithValidation";

const logger = getLogger(__filename);

interface EditorProps {
  session: Session | null;
  parentConvo?: Conversation;
  // callback after successful create
  onCreate?: (id: string) => void;

  i18n?: any;
  defaultContent?: string;

  // do not focus editor on load
  noFocus?: boolean;
  // event id
  eventId?: string;
}
interface EditorState {
  displayInfo: boolean;
  persona?: string;
  topic?: string;
  title?: string;
  // category: Category;
}

class EditorCls extends React.Component<EditorProps, EditorState> {
  public readonly state = {
    persona: "",
    topic: "",
    title: "",
    category: Category.entertainment,
    displayInfo: true,
  };

  private contentRef: React.MutableRefObject<HTMLDivElement | null> = React.createRef();

  componentDidUpdate() {
    const text = (this.contentRef!.current! as any).innerText;
    if (this.contentRef && !text && this.props.defaultContent) {
      this.contentRef.current!.innerText = this.props.defaultContent + "\n";
    }
  }

  render() {
    const { i18n } = this.props;
    const isReply = Boolean(this.props.parentConvo);
    let prompt = i18n.t("write_as");
    if (isReply) {
      prompt = i18n.t("reply_as");
    }

    return (
      <>
        <div className="flex items-center border border-gray-600 rounded-t bg-gray-600 text-white">
          <PersonaSelector2
            prompt={prompt}
            onSelect={(persona) => this.setState({ persona })}
          />
          {this.props.eventId ? (
            <div className="flex items-center gap-5">
              <span className="mx-2">{i18n.t("on")}</span>
              <EventHeader eventId={this.props.eventId} noLink />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={
            styles.container +
            " bg-white border border-gray-300 rounded-b-lg p-5"
          }
        >
          <header></header>
          {this.props.parentConvo ? (
            <div className={"flex flex-col "}>
              <div className="rounded-t-lg rounded-b-lg border border-gray-200">
                <Convo
                  convo={this.props.parentConvo}
                  hideActionBar
                  ellipsisText
                  nonInteractive
                  limitImgSize
                />
              </div>
              <span className={styles.threadLine}></span>
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-col">
            {/* title hidden when parent convo or event id is set */}
            <div hidden={!!this.props.parentConvo || !!this.props.eventId}>
              <InputWithValidation
                focusOnLoad={!this.props.noFocus}
                title={i18n.t("title_or_url")}
                onUpdateCb={(val: string) => this.setState({ title: val })}
              />
            </div>
            {/* <div
            hidden={!!this.props.parentConvo}
            className={"flex " + styles.line}
          >
            <label className="text-xl" htmlFor="to">
              {i18n.t("to")}
            </label>
            <input
              className="outline-none border border-gray-400"
              ref={this.toRef}
              name="to"
            />
          </div> */}
            <div className="mb-2">
              <div
                ref={this.contentRef}
                data-placeholder={i18n.t("editor_prompt")}
                className="rounded-lg border-gray-300 break-all p-2 outline-none j-contenteditable"
                contentEditable
              />
            </div>
            <div hidden className={"flex flex-end " + styles.category}>
              <span>{category2Icon(this.state.category)}</span>
            </div>
          </div>
          <footer className="flex justify-end items-center mt-5 pt-5 border-t border-gray-200">
            {/* TODO: quick editor - how to present <div>
              <button
                className="select-none cursor-pointer flex items-center rounded-lg bg-gray-100 p-2"
                onClick={() =>
                  trigger.ui.editorToggle({
                    mode: "quick",
                  })
                }
              >
                <i className={"material-icons-outlined "}>swap_horiz</i>
                <span>{i18n.t("editor_quick")}</span>
              </button>
            </div> */}
            {/* <div className={"flex flex-align-center " + styles.personaEditor}>
            <PersonaPrompt
              isReply={isReply}
              onChange={(selected: Array<any>) => {
                if (selected?.length) {
                  this.setState({
                    persona: selected[0].persona,
                    displayInfo: false,
                  });
                } else {
                  this.setState({ persona: undefined, displayInfo: true });
                }
              }}
            />
          </div> */}
            <div className={"flex items-center gap-2"}>
              <button
                onClick={() => {
                  trigger.ui.editorToggle({ isOpen: false });
                }}
                className="j-btn-cancel"
              >
                {i18n.t("cancel")}
              </button>
              <button
                className="j-btn-create"
                disabled={!Boolean(this.state.persona)}
                onClick={async () => {
                  // const to = (this.toRef?.current! as any).value;
                  const parentConvo = this.props.parentConvo;
                  const text = (this.contentRef?.current! as any).innerText;
                  const { persona, topic, title } = this.state;

                  // TODO: validation
                  try {
                    await post<CreateConversationRequest, { id: string }>(
                      api.account.conversation(),
                      CreateConversationRequest.fromPartial({
                        parentId: parentConvo?.id?.toString(),
                        // TODO: this logic should be enforced
                        // on backend
                        threadId:
                          parentConvo?.threadId?.toString() ||
                          parentConvo?.id?.toString(),
                        topic: topic || parentConvo?.topic,
                        eventId: this.props.eventId || parentConvo?.eventId,
                        title,
                        persona,
                        text,
                      })
                    );

                    // ORDER matters...
                    // TODO: fix this - aka allow concurrent
                    // state change
                    trigger.ui.notificationMessage({
                      message: i18n.t("post_success"),
                    });

                    setTimeout(
                      () =>
                        trigger.ui.editorToggle({
                          isOpen: false,
                        }),
                      0
                    );
                  } catch (err) {
                    logger.error(err);

                    trigger.ui.notificationMessage({
                      state: "error",
                      message: i18n.t("error/network"),
                    });
                  }
                }}
              >
                {i18n.t("button/publish")}
              </button>
            </div>
          </footer>
        </div>
      </>
    );
  }
}

// we need to introduce a fn component to use hook with `EditorCls`
const Editor = (props: Omit<EditorProps, "session">) => {
  const i18n = useI18n();
  const session = useSession();

  return <EditorCls i18n={i18n} {...props} session={session} />;
};

export default Editor;
