import { ReactionVal } from "@/gen/schema";

export default function reaction2Emoji(reaction: ReactionVal): string {
  if (reaction === "agree") {
    return "👍";
  } else if (reaction === "disagree") {
    return "👎";
  } else if (reaction === "angry") {
    return "😡";
  } else if (reaction === "happy") {
    return "😊";
  } else if (reaction === "sad") {
    return "😱";
  }
  return reaction;
}
