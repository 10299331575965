import React from "react";
import useSWR from "swr";

import api from "@/api";
import fetcher from "@/fetcher";
import ReactionBadge from "./ReactionBadge";

import styles from "./ReactionSummary.module.scss";
import { useI18n } from "@/i18n/hook";

interface ReactionSummaryProps {
  convoId?: string;
  withHeader?: boolean;
  topic?: string;
}

export default function ReactionSummary(props: ReactionSummaryProps) {
  const i18n = useI18n();
  const swrKey =
    (props.convoId && api.summary.conversation.reaction(props.convoId!)) ||
    api.summary.topic.reaction(props.topic!);

  const { data } = useSWR(swrKey, fetcher);

  if (!data) {
    return <></>;
  }

  const badges = data.reaction.summary.map((r: any, key: number) => (
    <ReactionBadge key={key} count={r.userCount} reaction={r.reactionVal} />
  ));

  return (
    <>
      {props.topic && props.withHeader ? (
        <header>
          <span>{i18n.t("reaction_to")}</span>
          <span>&nbsp;</span>
          <span>{`#${props.topic}`}</span>
        </header>
      ) : (
        <></>
      )}
      <div className={"flex " + styles.container}>{badges}</div>
    </>
  );
}
