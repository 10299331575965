const sizeMod = BigInt(2976221);
const patternMod = BigInt(2207);
const gradientMod = BigInt(10601);
const txtColorMod = BigInt(10601);
const bgColorMod = BigInt(33533);

export async function generateCssPatternClass(inputStr: string) {
  const hashBigInt = await hashString(inputStr);
  const size = getSize(hashBigInt);
  const pattern = getPattern(hashBigInt);
  const bgColor = "bg-" + getTailwindTextColor(hashBigInt, bgColorMod);
  const txtColor = "text-" + getTailwindTextColor(hashBigInt, txtColorMod);

  return `pattern-${pattern}-${size} ${bgColor} ${txtColor}`;
}

function getTailwindTextColor(seed: bigint, mod: bigint) {
  const gradients = [
    // "50",
    // "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
  ];
  const colors = [
    "slate",
    "red",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
  ];

  const grad = gradients[Number(seed % (mod + gradientMod)) % gradients.length];
  const color = colors[Number(seed % mod) % colors.length];

  return `${color}-${grad}`;
}

function getPattern(num: bigint): string {
  const patterns = [
    "checks",
    "cross-dots",
    "diagonal-lines",
    "diagonal-stripes",
    "dots",
    "grid",
    "horizontal-lines",
    "horizontal-stripes",
    "triangles",
    "vertical-lines",
    "vertical-stripes",
    "zigzag",
  ];
  const pIdx = Number(num % patternMod) % patterns.length;
  return patterns[pIdx];
}

function getSize(num: bigint): string {
  const sizes = ["sm", "md", "lg", "xl"];
  const size = Number(num % sizeMod) % sizes.length;
  return sizes[size];
}

const salt = "0a916e0f4c459b1x0hfx10200";
const _calculateHash: Record<string, bigint> = {};

async function hashString(str: string): Promise<bigint> {
  const input = str + salt;

  if (_calculateHash[input]) {
    return _calculateHash[input];
  }

  // Calculate the hash
  const hashBigInt = await sha256(input);

  _calculateHash[input] = hashBigInt;
  return hashBigInt;
}

// Common function for SHA-256 hashing
async function sha256(message: string) {
  if (typeof window !== "undefined" && window.crypto) {
    // Browser environment using Web Crypto API
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const buffer = await window.crypto.subtle.digest("SHA-256", data);

    const hashArray = Array.from(new Uint8Array(buffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    const hashBigInt = BigInt("0x" + hashHex);

    return hashBigInt;
  } else if (typeof require !== "undefined") {
    // Node.js environment using the crypto module
    const crypto = require("crypto");
    const hashBuffer = crypto.createHash("sha256").update(message).digest();
    const hashHex = hashBuffer.toString("hex");
    const hashBigInt = BigInt("0x" + hashHex);

    return hashBigInt;
  } else {
    throw new Error("Unsupported runtime environment");
  }
}
