import { timeFilter2RAfter } from "@/model/timeFilter";
import { PersonaFilter, TimeFilter } from "@/store/ty";
import querystring from "querystring";

const PREFIX = process.env.NEXT_PUBLIC_API_ROOT_URL;

export interface QueryParamOptions {
  cursor?: string;
  timeFilter?: TimeFilter;
  personaFilter?: PersonaFilter;
  // attach a query param to return threads
  returnThread?: boolean;
  // attach a query param to return event
  returnEvent?: boolean;
  topic?: string;
  groupBy?: string;
}

export default {
  event(eventId: string) {
    return PREFIX + "/event/" + eventId;
  },
  search: {
    persona: (q: string) => PREFIX + "/search/persona?q=" + q,
  },
  account: {
    account() {
      return PREFIX + "/account";
    },
    persona() {
      return PREFIX + "/account/persona";
    },
    verifyPersona() {
      return PREFIX + "/account/verify-persona";
    },
    reaction() {
      return PREFIX + "/account/reaction";
    },
    conversation() {
      return PREFIX + "/account/conversation";
    },
    convoLite() {
      return PREFIX + "/account/convo_lite";
    },
    event() {
      return PREFIX + "/account/event";
    },
    mailbox() {
      return PREFIX + "/account/mailbox";
    },
    mailboxUpdate(mailId: string) {
      return PREFIX + `/account/mailbox/${mailId}`;
    },
    checkUsername(username: string) {
      return PREFIX + `/account/check-username/${username}`;
    },
  },
  convo: {
    count(convoId: string, options?: QueryParamOptions) {
      return PREFIX + `/conv/${convoId}/counter`;
    },
  },
  summary: {
    thread: {
      reaction(threadId: string, options?: QueryParamOptions) {
        return PREFIX + `/summary/thread/${threadId}/reaction`;
      },
    },
    topic: {
      personas(topic: string, options?: QueryParamOptions) {
        return PREFIX + `/summary/topic/${topic}/persona`;
      },
      kws(topic: string, kwList: string, options?: QueryParamOptions) {
        return PREFIX + `/summary/topic/${topic}/conversation`;
      },
      reaction(topic: string, options?: QueryParamOptions) {
        return PREFIX + `/summary/topic/${topic}/reaction`;
      },
    },
    conversation: {
      reaction(id: string, options?: QueryParamOptions) {
        return PREFIX + `/summary/conversation/${id}/reaction`;
      },
    },
  },
  agg: {
    eventByTopic: aggUrlByTopic("event"),
    sentimentByTopic: aggUrlByTopic("sentiment"),
    personByTopic: aggUrlByTopic("meta"),
  },
  top: {
    trendByTopicX(topic: string, options?: QueryParamOptions) {
      return PREFIX + `/top/topic/${topic}/trend`;
    },
    convoByTopicX(topic: string, options?: QueryParamOptions) {
      const qStr = (options && optionsToQueryStr(options)) || "";
      return PREFIX + `/top/topic/${topic}/conversation${qStr}`;
    },
    convoLiteByPersonaX(persona: string, options?: QueryParamOptions) {
      return PREFIX + `/top/persona/${persona}/convo_lite`;
    },
    topicsByPersonaX(persona: string, options?: QueryParamOptions) {
      return PREFIX + `/top/persona/${persona}/topic`;
    },
    personasByTopicX(topic: string, options?: QueryParamOptions) {
      return PREFIX + `/top/topic/${topic}/persona`;
    },
    topicsByPersona(options?: QueryParamOptions) {
      const qStr = (options && optionsToQueryStr(options)) || "";
      return PREFIX + `/top/topic/persona${qStr}`;
    },
    personasByTopic(options?: QueryParamOptions) {
      const qStr = (options && optionsToQueryStr(options)) || "";
      return PREFIX + `/top/persona/topic${qStr}`;
    },
    persona(options?: QueryParamOptions) {
      return PREFIX + `/top/persona`;
    },
    topic(options?: QueryParamOptions) {
      return PREFIX + `/top/topic`;
    },
    event(persona?: string, options?: QueryParamOptions) {
      const qStr = (options && optionsToQueryStr(options)) || "";
      if (persona) {
        return PREFIX + `/top/persona/${persona}/event${qStr}`;
      }
      return PREFIX + `/top/event${qStr}`;
    },
  },
};

function optionsToQueryStr(options: QueryParamOptions): string {
  let qs: Record<string, string | undefined> = {};

  if (options.personaFilter) {
    qs = {
      ...qs,
      ...personaFilter2Query(options.personaFilter),
    };
  }
  if (options.timeFilter) {
    qs = {
      ...qs,
      ...timeFilter2Query(options.timeFilter),
    };
  }
  if (options.returnThread) {
    qs["returnThread"] = "";
  }
  if (options.returnEvent) {
    qs["returnEvent"] = "";
  }
  if (options.topic) {
    qs["topic"] = options.topic;
  }
  if (options.groupBy) {
    qs.groupBy = options.groupBy;
  }
  if (options.cursor) {
    qs.pagingCursor = options.cursor;
  }

  const res = querystring.stringify(qs);
  return res ? "?" + res : "";
}

/**
 * translate `PersonaFilter` to API filter query
 * @param pf
 * @returns
 */
function personaFilter2Query(pf: PersonaFilter) {
  // TODO: we only support `include` filter operation right now
  const keys = Object.keys(pf.filter ?? {});
  return {
    persona: keys.join(","),
  };
}

function timeFilter2Query(tf: TimeFilter) {
  // TODO: we only support `rAfter`
  // if (tf.selectedInterval) {
  return {
    rAfter: timeFilter2RAfter(tf.selectedInterval || ""),
  };
  // }
  // return {};
}

function aggUrlByTopic(type: string) {
  return (topic: string, options?: QueryParamOptions) => {
    const qs = {
      ...options,
      topic,
    };

    const qStr = optionsToQueryStr(qs);
    return PREFIX + `/agg/${type}${qStr}`;
  };
}
