import { useI18n } from "@/i18n/hook";
import { onStoreUpdate, trigger } from "@/store/store";
import React, { useEffect, useState } from "react";
import styles from "./AccountPrompt.module.scss";
import Loading from "./Loading";
import Modal from "./Modal";
import SignInForm from "./SignInForm";

interface AccountPromptProps {}

export default function AccountPrompt(props: AccountPromptProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const s = onStoreUpdate("$.ui.accountPromptVisible", (res) => {
      const visible = Boolean(res.data?.[0]);
      setIsOpen(visible);
    });

    // cleanup the stream on exit
    return () => {
      s.end(true);
    };
  }, []);

  return (
    <Modal
      hidden={!isOpen}
      onBgClick={() => trigger.ui.accountPrompt({ isOpen: false })}
    >
      <AccountPromptBody autofocus />
    </Modal>
  );
}

export function AccountPromptBody(props: { autofocus?: boolean }) {
  const i18n = useI18n();

  return (
    <div className="m-auto w-80v md:w-60v xl:w-40v 2xl:w-30v rounded-xl bg-white border border-gray-200 p-5 md:p-8">
      <h2 className="text-xl">{i18n.t("account_prompt/message")}</h2>
      <SignInForm autofocus={props.autofocus}></SignInForm>
    </div>
  );
}
