import throttle from "lodash.throttle";
import React, {
  FunctionComponent as FC,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./InputWithValidation.module.scss";

interface InputWithValidationProps {
  /**
   * *onUpdateCb* callback fn every time input value changes
   */
  onUpdateCb: (val: string) => void;
  /**
   * *focusOnLoad* focus cursor on this input on load
   */
  focusOnLoad?: boolean;
  /**
   * *loadingMessage* message to indicate that we are performing an async
   * validation of the input logic
   */
  loadingMessage?: string;
  /**
   * *errMessage* message to indicate current input value is invalid
   */
  errMessage?: string | React.ReactElement | Array<React.ReactElement>;
  /**
   * *title*
   */
  title?: string;
  /**
   * *renderHelpMessage* optional callback to return a help message component
   * the input element
   */
  renderHelpMessage?: (val: string) => JSX.Element | null;
}

const InputWithValidation: FC<InputWithValidationProps> = (
  props: InputWithValidationProps
) => {
  const nameRef = useRef(null);
  const [val, setVal] = useState("");
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    if (props.focusOnLoad) {
      (nameRef.current as any).focus();
    }
  }, []);

  function handleInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const inputVal = e.target.value;
    if (props.onUpdateCb) {
      props.onUpdateCb(inputVal);
    }
    setVal(inputVal);
  }

  const title = props.title || "temp";
  const titleCss =
    highlighted || val ? "transform-gpu -translate-y-6 scale-75 " : "";
  return (
    <div className="flex flex-col">
      <div className="flex flex-align-center justify-between w-full border-2 border-gray-200 px-4 py-3 rounded-lg shadow-sm ">
        <span
          className={
            "select-none pointer-events-none text-gray-400 absolute bg-white transition-transform duration-150 origin-top-left text-xl " +
            titleCss
          }
        >
          {title}
        </span>
        <input
          onFocus={() => setHighlighted(true)}
          onBlur={() => setHighlighted(false)}
          onChange={throttle((e) => handleInput(e))}
          onKeyDown={(e) => {}}
          ref={nameRef}
          type="text"
          className="w-full focus:outline-none focus:shadow-outline text-gray-600 mr-2 font-medium"
        />
        <div>
          {props.renderHelpMessage ? props.renderHelpMessage(val) : <></>}
        </div>
      </div>
      <div className="block w-full my-2">
        <span className="block h-full">
          {!props.loadingMessage ? (
            <></>
          ) : (
            <span className="text-gray-300">{props.loadingMessage}</span>
          )}
          {!props.errMessage ? (
            <span>&nbsp;</span>
          ) : typeof props.errMessage === "string" ? (
            <span className="text-red-500">{props.errMessage}</span>
          ) : (
            <>{props.errMessage}</>
          )}
        </span>
      </div>
    </div>
  );
};

export default InputWithValidation;
