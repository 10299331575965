// AUTOGENERATED
export enum ReactionType {
  reaction = "reaction",
  conversation = "conversation",
}

export enum ReactionVal {
  agree = "agree",
  disagree = "disagree",
  sad = "sad",
  happy = "happy",
  angry = "angry",
  reply = "reply",
  comment = "comment",
}

export enum TargetType {
  topic = "topic",
  persona = "persona",
  conversation = "conversation",
}

export enum TextTone {
  positive = "positive",
  normal = "normal",
  sad = "sad",
}

export enum TextExpression {
  whisper = "whisper",
  normal = "normal",
  shout = "shout",
}

export enum Category {
  food = "food",
  travel = "travel",
  outdoor_activity = "outdoor_activity",
  clothes = "clothes",
  entertainment = "entertainment",
  general = "general",
}
export const Slugs: { [k: string]: string } = {
  user_persona: "user_persona",
  user_mask: "user_mask",
  u: "user",
  t: "topic",
  re: "reaction",
  pmx: "pmailbox",
  p: "persona",
  nickname: "nickname",
  mx: "mailbox",
  evt: "event",
  convo_lite: "convo_lite",
  conv: "conversation",
  apikey: "apikey",
};
export type SlugTy =
  | "user_persona"
  | "user_mask"
  | "u"
  | "t"
  | "re"
  | "pmx"
  | "p"
  | "nickname"
  | "mx"
  | "evt"
  | "convo_lite"
  | "conv"
  | "apikey";
export type ResourceTy =
  | "user_persona"
  | "user_mask"
  | "user"
  | "topic"
  | "reaction"
  | "pmailbox"
  | "persona"
  | "nickname"
  | "mailbox"
  | "event"
  | "convo_lite"
  | "conversation"
  | "apikey";

export const Tables = {
  user_persona: "user_persona",
  user_mask: "user_mask",
  user: "user",
  topic: "topic",
  reaction: "reaction",
  pmailbox: "pmailbox",
  persona: "persona",
  nickname: "nickname",
  mailbox: "mailbox",
  event: "event",
  convo_lite: "convo_lite",
  conversation: "conversation",
  apikey: "apikey",
};

export const Shorthand = {
  user_persona: "user_persona",
  user_mask: "user_mask",
  user: "u",
  topic: "t",
  reaction: "re",
  pmailbox: "pmx",
  persona: "p",
  nickname: "nickname",
  mailbox: "mx",
  event: "evt",
  convo_lite: "convo_lite",
  conversation: "conv",
  apikey: "apikey",
};

export interface User_persona {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  user?: string;
  persona?: string;
  verifyEmail?: string;
  verified?: boolean;
}

export function extract2User_persona(obj: any): User_persona {
  return {};
}

export interface User_mask {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  user?: string;
  nickname?: string;
  tombstone?: number;
}

export function extract2User_mask(obj: any): User_mask {
  return {};
}

export interface User {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  contactEmail?: string;
  contactPhone?: string;
  preferences?: string;
  contactEmailVerified?: boolean;
  contactPhoneVerified?: boolean;
  isAdmin?: boolean;
}

export function extract2User(obj: any): User {
  return {
    contactEmail: obj["ContactEmail"] || null,
    contactPhone: obj["ContactPhone"] || null,
    preferences: obj["Preferences"] || null,
    isAdmin: obj["IsAdmin"] || null,
  };
}

export interface Topic {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  name?: string;
}

export function extract2Topic(obj: any): Topic {
  return {};
}

export interface Reaction {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  reactionType?: string;
  reactionVal?: string;
  targetType?: string;
  targetVal?: string;
  targetThreadId?: string;
  convTopic?: string;
  convPersona?: string;
  country?: string;
  city?: string;
  persona?: string;
  user?: string;
}

export function extract2Reaction(obj: any): Reaction {
  return {};
}

export interface Pmailbox {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  conversation?: string;
  persona?: string;
}

export function extract2Pmailbox(obj: any): Pmailbox {
  return {};
}

export interface Persona {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  name?: string;
  deactivated?: boolean;
  attribute1JSON?: string;
  attribute2JSON?: string;
  attribute3JSON?: string;
}

export function extract2Persona(obj: any): Persona {
  return {};
}

export interface Nickname {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  nickname?: string;
  user?: string;
}

export function extract2Nickname(obj: any): Nickname {
  return {};
}

export interface Mailbox {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  isRead?: boolean;
  conversation?: string;
  user?: string;
}

export function extract2Mailbox(obj: any): Mailbox {
  return {};
}

export interface Event {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  topic?: string;
  title?: string;
  descriptionShort?: string;
  eventTime?: number;
  descriptionLong?: string;
  sourceLink?: string;
  staffVerified?: boolean;
  user?: string;
  userMaskId?: string;
  url?: string;
}

export function extract2Event(obj: any): Event {
  return {};
}

export interface Convo_lite {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  verb?: string;
  object?: string;
  published?: boolean;
  persona?: string;
  user?: string;
}

export function extract2Convo_lite(obj: any): Convo_lite {
  return {};
}

export interface Conversation {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  title?: string;
  topic?: string;
  eventId?: string;
  text?: string;
  textTone?: string;
  textExpression?: string;
  category?: string;
  published?: boolean;
  parentId?: string;
  threadId?: string;
  targetPersona?: string;
  persona?: string;
  personaVerified?: boolean;
  userPersonaId?: string;
  userNickname?: string;
  userMaskId?: string;
  user?: string;
  convoLiteKey?: string;
}

export function extract2Conversation(obj: any): Conversation {
  return {};
}

export interface Apikey {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  key?: string;
  disabled?: boolean;
}

export function extract2Apikey(obj: any): Apikey {
  return {};
}
