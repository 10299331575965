import api from "@/api";
import { post } from "@/fetcher";
import { CreateConvoLiteRequest } from "@/gen/api";
import { useI18n } from "@/i18n/hook";
import { trigger } from "@/store/store";
import { FunctionComponent as FC, useRef, useState } from "react";
import PersonaSelector2 from "./PersonaSelector2";

interface QuickEditorProps {
  prompt?: string;
  suggestedVerb?: string;
  suggestedObject?: string;
}

// TODO: dynamic suggestion on "what you doing"
const QuickEditor: FC<QuickEditorProps> = (props: QuickEditorProps) => {
  const [persona, setPersona] = useState("");
  const i18n = useI18n();

  const verbRef = useRef<HTMLInputElement>(null);
  const objRef = useRef<HTMLInputElement>(null);

  const promptTxtCss =
    "text-blue-400 border-b-4 border-blue-300 py-3 px-2 xl:px-5 bg-white placeholder-gray-400 appearance-none block focus:outline-none text-center";

  function isValid() {
    const verb = verbRef?.current?.value;
    const obj = objRef?.current?.value;
    return Boolean(verb && obj && persona);
  }

  return (
    <>
      <PersonaSelector2
        prompt={i18n.t("share_as")}
        onSelect={(p) => setPersona(p)}
      />
      <div className="bg-white p-5 flex flex-col gap-5 md:gap-10 items-center border border-gray-200 rounded-b-lg">
        <div className="self-start text-2xl flex gap-1">
          <span>{i18n.t("editor_quick_prompt")}</span>
        </div>
        <div className="text-3xl flex flex-wrap items-center justify-around gap-2 xl:gap-5">
          <input
            ref={verbRef}
            placeholder={props.suggestedVerb ?? "reading"}
            className={promptTxtCss}
          />
          <input
            ref={objRef}
            placeholder={props.suggestedObject ?? "harry potter"}
            className={promptTxtCss}
          />
        </div>
        <br />
        <div className="w-full flex justify-between">
          <div className="">
            <button
              className="select-none cursor-pointer flex items-center rounded-lg bg-gray-100 p-2"
              onClick={() =>
                trigger.ui.editorToggle({
                  mode: "norm",
                })
              }
            >
              <i className={"material-icons-outlined "}>swap_horiz</i>
              <span>{i18n.t("editor_norm")}</span>
            </button>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => {
                trigger.ui.editorToggle({ isOpen: false });
              }}
              className="j-btn-cancel"
            >
              {i18n.t("cancel")}
            </button>
            <button
              className="j-btn-create action"
              disabled={!isValid()}
              onClick={async () => {
                // trigger a post action
                const verb = verbRef?.current!.value;
                const obj = objRef?.current!.value;

                // TODO: validation
                await post<CreateConvoLiteRequest, { id: string }>(
                  api.account.convoLite(),
                  CreateConvoLiteRequest.fromPartial({
                    verb,
                    persona,
                    object: obj,
                  })
                );
              }}
            >
              {i18n.t("share")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickEditor;
