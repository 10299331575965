import { useI18n } from "@/i18n/hook";
import React, { FunctionComponent as FC } from "react";
import styles from "./SignInForm.module.scss";

interface SignInFormProps {
  autofocus?: boolean;
}

const SignInForm: FC<SignInFormProps> = (props: SignInFormProps) => {
  const i18n = useI18n();

  return (
    <form
      className="mt-10 md:p-5 rounded-lg"
      method="POST"
      action="/api/auth/signin"
    >
      <input type="hidden" name="csrfToken" />
      <div>
        <label className="block mb-2 text-indigo-500" htmlFor="email">
          {i18n.t("email")}
        </label>
        <input
          className="w-full p-2 mb-6 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-100"
          type="email"
          name="email"
          autoFocus={props.autofocus}
        />
      </div>
      <div>
        <input
          className="cursor-pointer w-full bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded"
          type="submit"
          value={i18n.t("signin_or_new_account")}
        />
      </div>
    </form>
  );
};

export default SignInForm;
