import { ReactionVal } from "@/gen/schema";
import * as humanize from "humanize-plus";
import reaction2Emoji from "@/lib/reaction2Emoji";
import React from "react";
import styles from "./ReactionBadge.module.scss";

interface ReactionBadgeProps {
  count: string;
  reaction: ReactionVal;
}

export default function ReactionBadge(props: ReactionBadgeProps) {
  const hcount = humanize.compactInteger(parseInt(props.count, 10));
  return (
    <div className={"flex flex-align-center " + styles.container}>
      <span className={styles.count}>{hcount}</span>
      <span className={styles.icon}>{reaction2Emoji(props.reaction)}</span>
    </div>
  );
}
