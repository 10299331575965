import { Category } from "@/gen/schema";

export default function category2Icon(cat: Category, className?: any) {
  let text;
  if (cat === Category.travel) {
    text = "flight_takeoff";
  } else if (cat === Category.food) {
    text = "local_dining";
  } else if (cat === Category.clothes) {
    text = "checkroom";
  } else if (cat === Category.entertainment) {
    text = "weekend";
  } else if (cat === Category.outdoor_activity) {
    text = "sports";
  } else if (cat === Category.general) {
    return <></>;
  }

  return <i className={"material-icons-outlined " + className ?? ""}>{text}</i>;
}
