import React, { useEffect } from "react";
import styles from "./Modal.module.scss";

interface ModalProps {
  children: any;

  fixedAtBottom?: boolean;
  hidden?: boolean;
  storeKey?: string;
  onBgClick?: () => void;
}

export default function Modal(props: ModalProps) {
  const onEscKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      props.onBgClick && props.onBgClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onEscKey, false);
    return () => {
      document.removeEventListener("keydown", onEscKey);
    };
  }, []);

  return (
    <div className={styles.modal} hidden={props.hidden}>
      <div
        onClick={(e) => {
          if (e.currentTarget === e.target && props.onBgClick) {
            e.preventDefault();
            props.onBgClick();
          }
        }}
        className={
          "flex flex-center flex-align-center " + styles.childrenContainer
        }
      >
        <div
          className={`w-full lg:w-3/5 md:mx-0 mx-5 ${
            props.fixedAtBottom ? "fixed bottom-0 sm:block sm:bottom-auto" : ""
          }`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
