import api from "@/api";
import fetcher from "@/fetcher";
import type { Conversation } from "@/gen/schema";
import type { ConvoWithStats } from "@/model/ty";
import humanize from "humanize-plus";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import useSWR from "swr";
import styles from "./Convo.module.scss";
import ConvoActionBar from "./ConvoActionBar";
import ReactionSummary from "./ReactionSummary";
import UserSays from "./UserSays";
import user from "@/server/api/user";

interface ConvoProps extends TopRightSectionProps {
  convo: ConvoWithStats;
  nonInteractive?: boolean;
  hideActionBar?: boolean;
  noBorder?: boolean;
  jorceTopBorder?: boolean;
  // TODO
  limitImgSize?: boolean;
  /** ellipsisText whether to abridge the content */
  ellipsisText?: boolean;
  /** largeText whether to use large font */
  largeText?: boolean;

  noEventHeader?: boolean;
  forceTopBorder?: boolean;
}

const Convo = (props: ConvoProps) => {
  const router = useRouter();

  useEffect(() => {
    import("@github/time-elements");
  });

  const convo = props.convo;
  const className = [
    "bg-white hover:shadow-md cursor-pointer flex-col flex-space-between",
    props.noBorder
      ? "sm:rounded-t-lg sm:rounded-b-lg"
      : "sm:rounded-lg border border-gray-300",
    props.forceTopBorder ? "border-t border-gray-300" : "",
  ].join(" ");

  return (
    <div
      // `"outer"` is for html identification and is a no-op
      className="convo-outer"
      onClick={() => {
        if (!props.nonInteractive) {
          router.push(`/convo/${convo.id!}`);
        }
      }}
    >
      {/* <div className="flex flex-align-center flex-space-between">
        <div className={styles.personaContainer}>
          <Persona persona={convo.persona!} country={convo.country} />
        </div>
      </div> */}
      <div className={`p-4 md:p-6 md:pb-4 ${className}`}>
        <div className="flex justify-between ">
          <div className={styles.author}>
            <UserSays
              userPersona={convo.persona!}
              userNickname={convo.userNickname!}
              targetPersona={convo.targetPersona}
              eventId={convo.eventId}
              noEventHeader={props.noEventHeader}
              title={convo.title}
              // parent={convo.parentId}
              personaVerified={convo.personaVerified}
            />
          </div>
          <div className="flex flex-align-center">
            <TopRightSection {...props} />
          </div>
        </div>
        <div
          className={`mt-2 flex flex-col gap-2 ${
            props.largeText ? "text-lg md:text-xl" : "text-md md:text-lg"
          }`}
        >
          <div
            className={props.ellipsisText ? styles.ellipsisTextContainer : ""}
          >
            <p>{props.convo.text!}</p>
            {/* <p>
              最近很頻繁一直看到這個女生出現在各種表特版
              前陣子原本粉絲只有1000多現在變超多
              為了避免大家說我在亂公審把帳號馬掉了呵呵
              只是想知道大家真的有覺得這算漂亮嗎？
              感覺現在網路上的風氣就是修圖修超大放到ig
              然後就會有一堆人跑來追蹤留言說好好看
              然後不要說我嫉妒人家==先猜等等一定會有人這樣說 真的沒什麼好嫉妒的
              只是覺得很奇怪 不是說長得不好看 但就只是算普通的女生吧
              走在路上也不會特別多看的那種 也不知道是不是自己花錢買榜的
              而且現在很多帳號都是可以付錢宣傳自己 這樣真的有意義嗎⋯⋯
              每個長得普普的人只要花錢都可以當網美了
            </p> */}
          </div>
          {/* <p className="">
            最近很頻繁一直看到這個女生出現在各種表特版
            前陣子原本粉絲只有1000多現在變超多
            為了避免大家說我在亂公審把帳號馬掉了呵呵
            只是想知道大家真的有覺得這算漂亮嗎？
            感覺現在網路上的風氣就是修圖修超大放到ig
            然後就會有一堆人跑來追蹤留言說好好看
            然後不要說我嫉妒人家==先猜等等一定會有人這樣說 真的沒什麼好嫉妒的
            只是覺得很奇怪 不是說長得不好看 但就只是算普通的女生吧
            走在路上也不會特別多看的那種 也不知道是不是自己花錢買榜的
            而且現在很多帳號都是可以付錢宣傳自己 這樣真的有意義嗎⋯⋯
            每個長得普普的人只要花錢都可以當網美了
          </p>  */}
        </div>
        <div className="pb-5 flex justify-around gap-5 hidden">
          {/* 
          <div className="">
            <img
              className="rounded-lg w-auto h-auto"
              src="https://imgur.dcard.tw/tg3QdQWh.jpg"
              style={props.limitImgSize ? { maxHeight: "35vh" } : {}}
              alt=""
            />
          </div>
          <div className=" ">
            <video
              className="rounded-lg w-auto"
              src="https://i.imgur.com/S012kj5.mp4"
            />
          </div> */}
        </div>

        {/* <AggregateUpdate nonInteractive /> */}

        <div className="flex justify-between mt-5">
          <ReactionSummary convoId={convo.id}></ReactionSummary>
          <div className={"flex flex-align-center " + styles.actionContainer}>
            {/* <Reaction></Reaction> */}
            <time-ago
              className="subtle-txt place-self-end"
              dateTime={convo.createdAt}
            />
          </div>
        </div>
        <div className="mt-5">
          <ConvoActionBar convo={convo} hidden={props.hideActionBar} />
        </div>
      </div>
    </div>
  );
};

interface TopRightSectionProps {
  convo: Conversation;
  /**
   * fetchViewCounter - whether to fetch current view count and render the badge
   */
  fetchViewCounter?: boolean;
  /**
   *
   */
}

function TopRightSection(props: TopRightSectionProps) {
  const { data, isValidating } = useSWR(
    props.fetchViewCounter ? api.convo.count(props.convo.id!) : null,
    fetcher
  );

  if (
    !props.fetchViewCounter ||
    isValidating ||
    !data ||
    (data && (data.counter === "0" || parseInt(data.counter, 10) < 10))
  ) {
    return <></>;
  }

  return (
    <>
      <span className="flex gap-1 items-center text-sm bg-blue-100 rounded-2xl border border-white py-1 px-2">
        <i className={"text-sm font-semibold material-icons-outlined"}>
          visibility
        </i>
        {humanize.compactInteger(parseInt(data.counter, 10))}
      </span>
      {/* <i className={"material-icons-outlined " + styles.info}>
              trending_up
            </i> */}
      {/* <span className={styles.sentiment}> &#8599; 92% &nbsp;</span>
            <i className={"material-icons-outlined " + styles.info}>info</i> */}
    </>
  );
}

export default Convo;
