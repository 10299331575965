import AccountPrompt from "./AccountPrompt";
import EditorButton from "./EditorButton";
import EventEditorButton from "./EventEditorButton";
import NotificationCenter from "./NotificationCenter";
import PersonaPrompt from "./PersonaPrompt";
import React, { useEffect, useRef } from "react";
import ReactionModal from "./ReactionModal";
import { FilterToolbarModal } from "./FilterToolbarModal";
import { PersonaItem } from "@/lib/personaUtil";

interface PageContentProps {
  children?: React.ReactNode;
  row?: boolean;
  excludeModals?: boolean;
  fullWidth?: boolean;

  personaFilterStaticItems?: PersonaItem[];
}
export default function PageContent(props: PageContentProps) {
  return (
    <div
      className={`x-pagecontent mb-10 flex justify-center min-h-full px-3 ${
        !props.fullWidth ? "md:px-10" : ""
      }`}
    >
      <div className={`w-100v ${!props.fullWidth ? "md:w-85v xl:w-80v" : ""}`}>
        <div className="grid grid-cols-6 gap-y-2 md:gap-y-5 md:gap-x-5 lg:gap-x-8 md:grid-cols-12">
          {props.children}
        </div>
      </div>
      {props.excludeModals ? (
        <></>
      ) : (
        <>
          <PersonaPrompt />
          <AccountPrompt />
          <EditorButton />
          {/* <EventEditorButton /> */}
          <ReactionModal />
          <NotificationCenter />
          <FilterToolbarModal
            personaFilterStaticItems={props.personaFilterStaticItems || []}
          />
        </>
      )}
    </div>
  );
}
