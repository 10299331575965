import { trigger } from "@/store/store";
import type {
  NotificationMessage as NotificationMessageTy,
  NotificationMessageState as NotificationMessageStateTy,
} from "@/store/ty";

import { useEffect, useState } from "react";

import styles from "./Notification.module.scss";
import { getLogger } from "@/log";

const DEFAULT_NOTIFICATION_DISMISSAL_MILLI = 5000;
const logger = getLogger(__filename);

interface NotificationMessageProps {
  message: NotificationMessageTy;
}

export function NotificationMessage(props: NotificationMessageProps) {
  const { message } = props;
  const [xBtnVisible, setXBtnVisible] = useState(true);

  useEffect(() => {
    if (!message.sticky) {
      setTimeout(() => {
        trigger.ui.notificationMessageDismissal(message.id!);
      }, DEFAULT_NOTIFICATION_DISMISSAL_MILLI);
    }
  }, []);

  return (
    <div
      className="flex flex-col max-w-sm min-w-sm relative"
      // onMouseOver={() => setXBtnVisible(true)}
      // onMouseOut={() => setXBtnVisible(false)}
    >
      <div
        className={`
          px-5 py-2 shadow-sm rounded flex items-center gap-2 md:gap-5 justify-between ${
            styles[message.state!]
          }`}
      >
        <div className="flex gap-4 font-light text-sm md:text-base">
          <i className="material-icons-outlined background-white">
            {state2MaterialIcon(message.state!)}
          </i>
          <p>{message.message}</p>
        </div>
        {message.url ? (
          <div className="font-light text-sm md:text-base">
            <a href={message.url} target="_blank">
              url
            </a>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`flex items-center visible ${
            xBtnVisible ? "sm:visible" : "sm:invisible"
          }`}
        >
          <button
            className={"rounded-full"}
            onClick={() => {
              trigger.ui.notificationMessageDismissal(message.id!);
            }}
          >
            <i className={"flex items-center material-icons-outlined"}>close</i>
          </button>
        </div>
      </div>
    </div>
  );
}

function state2MaterialIcon(state: NotificationMessageStateTy) {
  if (state === "error") {
    return "error_outline";
  } else if (state === "ok") {
    return "check_circle";
  } else if (state === "info") {
    return "info";
  } else {
    return "warning_amber";
  }
}
