import { useI18n } from "@/i18n/hook";
import Link from "next/link";
import path from "@/server/path";
import React, { FunctionComponent as FC, useEffect, useState } from "react";
import styles from "./PersonaPrompt.module.scss";
import { onStoreUpdate, trigger } from "@/store/store";
import Modal from "./Modal";

interface PersonaPromptProps {}

const PersonaPrompt: FC<PersonaPromptProps> = (props: PersonaPromptProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const s = onStoreUpdate("$.ui.personaPromptVisible", (res) => {
      const visible = Boolean(res.data?.[0]);
      setIsOpen(visible);
    });

    // cleanup the stream on exit
    return () => {
      s.end(true);
    };
  }, []);

  return (
    <Modal
      hidden={!isOpen}
      onBgClick={() => trigger.ui.personaPrompt({ isOpen: false })}
    >
      <PersonaPromptBody />
    </Modal>
  );
};

export function PersonaPromptBody(props: PersonaPromptProps) {
  const i18n = useI18n();

  return (
    <div className="m-auto w-90v md:w-50v xl:w-40v 2xl:w-30v rounded-xl bg-white border border-gray-200 p-7 md:p-10">
      <span className="text-2xl">
        <span>{i18n.t("no_persona_prompt_title")}</span>
      </span>
      <span className="my-5 block border border-b-1 border-gray-200" />
      <h2>{i18n.t("what_is_persona")}</h2>
      <div className="flex items-center flex-col">
        <span className="text-xl">
          {i18n.t("what_is_persona_body", {
            app: i18n.t("app_name"),
          })}
        </span>
      </div>
      <br />
      <Link href={path.newPersona}>
        <a
          onClick={() => trigger.ui.personaPrompt({ isOpen: false })}
          className="text-center block btn-create w-full"
        >
          {i18n.t("create_persona_prompt_button")}
        </a>
      </Link>
    </div>
  );
}

export default PersonaPrompt;
