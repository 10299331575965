import { getQueryVariable } from "@/lib/urlUtil";
import { onStoreUpdate, trigger } from "@/store/store";
import type {
  NotificationMessage as NotificationMessageTy,
  NotificationUpdate,
} from "@/store/ty";
import React, { FunctionComponent as FC, useEffect, useState } from "react";
import { NotificationMessage } from "./Notification";
import { getLogger } from "@/log";

const logger = getLogger(__filename);

interface NotificationCenterProps {
  children?: React.ReactElement;
}

const NotificationCenter: FC<NotificationCenterProps> = (
  props: NotificationCenterProps
) => {
  const [notifications, setNotifications] = useState<NotificationMessageTy[]>(
    []
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const msg = getQueryVariable("notifyMessage");
      const state = getQueryVariable("notifyLevel");

      if (msg && state) {
        logger.info("trigger", msg);
        trigger.ui.notificationMessage({
          message: msg,
          state: state as any,
        });
      }
    }
  }, []);

  useEffect(() => {
    const stream = onStoreUpdate("$.ui.notification", async (res) => {
      const nObj = (res.data[0] ?? {}) as NotificationUpdate;
      logger.info("nobj", nObj);
      const filtered = nObj.messages?.filter((n) => !n.read);
      setNotifications(filtered ?? []);
    });

    return () => {
      stream.end(true);
    };
  }, []);

  return (
    <div className="z-50 fixed flex flex-col gap-5 top-5 right-5">
      {notifications.reverse().map((n, idx) => (
        <NotificationMessage key={idx} message={n}></NotificationMessage>
      ))}
    </div>
  );
};

export default NotificationCenter;
