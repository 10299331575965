import { useI18n } from "@/i18n/hook";
import { useRouter } from "next/router";
import { FunctionComponent as FC } from "react";
import EventHeader from "./EventHeader";
import Persona from "./Persona";

interface UserSaysProps {
  // deprecated
  userNickname: string;
  userPersona: string;

  hidePersona?: boolean;
  targetPersona?: string;
  eventId?: string;

  colLayout?: boolean;
  topic?: string;
  showTopic?: boolean;

  noEventHeader?: boolean;
  title?: string;
  personaVerified?: boolean;
}

const UserSays: FC<UserSaysProps> = (props: UserSaysProps) => {
  const i18n = useI18n();
  const router = useRouter();

  return (
    <div className={"flex flex-col tracking-tighter"}>
      <div className="flex-col flex lg:items-center lg:flex-row gap-1">
        <div className="flex gap-1">
          {!props.hidePersona ? (
            <UserSaysPersonaI18n
              locale={router.locale}
              i18n={i18n}
              persona={props.userPersona}
              personaVerified={props.personaVerified}
            />
          ) : (
            <></>
          )}
          <div className="flex items-center text-sm font-semibold text-blue-400">
            <span>{props.userNickname}</span>
          </div>
          {props.targetPersona ? (
            <div className="flex items-center gap-1">
              <i className="material-icons-outlined">double_arrow</i>
              <Persona
                bgColor="white"
                borderColor="border-gray-600"
                persona={"boo"}
              />
            </div>
          ) : (
            <></>
          )}
          {props.title ? (
            <div className="flex items-center">
              <span className="mx-2">{i18n.t("on")}</span>
              <span className="font-bold">{props.title}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        {props.eventId && !props.noEventHeader ? (
          <div className="flex items-center">
            <span className="mx-2">{i18n.t("on")}</span>
            <EventHeader eventId={props.eventId} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

function UserSaysPersonaI18n(props: {
  i18n: ReturnType<typeof useI18n>;
  locale?: string;
  persona: string;
  personaVerified?: boolean;
}) {
  const { i18n, locale } = props;

  if (!locale || locale === "en") {
    return (
      <>
        <Persona
          key={props.persona}
          isVerified={props.personaVerified}
          persona={props.persona}
        />
      </>
    );
  }
  return (
    <span className="flex items-center gap-1">
      <Persona key={props.persona} persona={props.persona} />
      <span>{i18n.t("de")}</span>
    </span>
  );
}

export default UserSays;
