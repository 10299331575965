import { useI18n } from "@/i18n/hook";
import { PersonaItem } from "@/lib/personaUtil";
import { TIME_FILTER_INTERVALS } from "@/model/timeFilter";
import { onStoreUpdate, trigger } from "@/store/store";
import type { FilterToolbar, PersonaFilter, TimeFilter } from "@/store/ty";
import React, { FunctionComponent as FC, useEffect, useState } from "react";
import Modal from "./Modal";
import { FilterProps } from "./util/filterToolbarTy";

type ModalTy = "timeFilter" | "personaFilter" | "geoFilter" | "";

export interface FilterToolbarModalProps extends FilterProps {}

export function FilterToolbarModal(props: FilterToolbarModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTy, setModalTy] = useState<ModalTy>("");

  useEffect(() => {
    const s = onStoreUpdate("$.ui.filterToolbar", (res) => {
      const state = res.data[0] as FilterToolbar;
      if (state?.timeFilter?.modalVisible) {
        setIsOpen(true);
        setModalTy("timeFilter");
      } else if (state?.personaFilter?.modalVisible) {
        setIsOpen(true);
        setModalTy("personaFilter");
      } else if (state?.geoFilter?.modalVisible) {
        setIsOpen(true);
        setModalTy("geoFilter");
      } else {
        setIsOpen(false);
        setModalTy("");
      }
    });
    return () => {
      s.end(true);
    };
  }, []);

  return (
    <Modal
      hidden={!isOpen}
      onBgClick={() => {
        if (modalTy !== "") {
          trigger.ui.filterToolbar({
            [modalTy]: { modalVisible: false },
          });
        }
      }}
    >
      <FilterToolbarModalBody {...props} />
    </Modal>
  );
}

interface TimeFilterModalBodyProps extends TimeFilter {}
export function TimeFilterModalBody(props: TimeFilterModalBodyProps) {
  const i18n = useI18n();

  function saveFilterSelection(interval: string) {
    trigger.ui.filterToolbar({
      timeFilter: {
        selectedInterval: interval,
        modalVisible: false,
      },
    });
  }

  const selected = props.selectedInterval ?? TIME_FILTER_INTERVALS[0];
  return (
    <ul className="px-5 my-5 flex flex-col gap-1">
      {TIME_FILTER_INTERVALS.map((interval) => (
        <li
          key={interval}
          onClick={() => saveFilterSelection(interval)}
          className="hover:bg-gray-100 p-2 pointer select-none flex items-center justify-between"
        >
          <span>{i18n.t(`timeFilter/${interval}`)}</span>
          <span hidden={selected !== interval}>
            <i className="material-icons-outlined">done</i>
          </span>
        </li>
      ))}
    </ul>
  );
}

interface PersonaFilterModalBodyProps extends PersonaFilter, FilterProps {}
export function PersonaFilterModalBody(props: PersonaFilterModalBodyProps) {
  const i18n = useI18n();
  const { personaFilterStaticItems, filter: pfFilter } = props;

  function savePersonaSelection(selected: PersonaItem) {
    trigger.ui.filterToolbar({
      personaFilter: {
        filter: { [selected.persona]: true },
        modalVisible: false,
      },
    });
  }

  const items = personaFilterStaticItems;
  const displayItems = items.map((item) => item.persona);
  const itemsTuple: Array<[PersonaItem, string]> = items.map((item, i) => [
    item,
    displayItems[i],
  ]);
  const selected = new Set(Object.keys(pfFilter || {}));

  return (
    <ul className="px-5 my-5 flex flex-col gap-1">
      {itemsTuple.map(([item, displayItem]) => (
        <li
          onClick={() => savePersonaSelection(item)}
          className="hover:bg-gray-100 p-2 pointer select-none flex items-center justify-between"
        >
          <span>{displayItem}</span>
          <span hidden={!selected.has(displayItem)}>
            <i className="material-icons-outlined">done</i>
          </span>
        </li>
      ))}
    </ul>
  );
}

const FilterToolbarModalBody: FC<FilterToolbarModalProps> = (
  props: FilterToolbarModalProps
) => {
  const i18n = useI18n();
  const [ftState, setFtState] = useState<FilterToolbar>({});
  useEffect(() => {
    const cb = onStoreUpdate("$.ui.filterToolbar", (res) => {
      const state = res.data[0] as FilterToolbar;
      if (state) {
        setFtState({ ...state });
      }
    });
    return () => {
      cb.end(true);
    };
  }, []);

  let modalHeader = <></>;
  let modalContent = <></>;
  if (ftState) {
    if (ftState.geoFilter?.modalVisible) {
      modalHeader = <span>{i18n.t("filter_by_geo")}</span>;
      modalContent = <></>;
    } else if (ftState.timeFilter?.modalVisible) {
      modalHeader = <span>{i18n.t("filter_by_time")}</span>;
      modalContent = <TimeFilterModalBody {...ftState.timeFilter} />;
    } else if (ftState.personaFilter?.modalVisible) {
      modalHeader = <span>{i18n.t("filter_by_persona")}</span>;
      modalContent = (
        <PersonaFilterModalBody
          personaFilterStaticItems={props.personaFilterStaticItems}
          {...ftState.personaFilter}
        />
      );
    }
  }

  return (
    <div className="m-auto w-90v md:w-50v xl:w-40v 2xl:w-30v rounded-xl bg-white border border-gray-200 ">
      <div className="bg-gray-200 p-3 rounded-t-lg font-semi-bold">
        {modalHeader}
      </div>
      <div className="max-h-96 overflow-y-auto">{modalContent}</div>
    </div>
  );
};

export default FilterToolbarModalBody;
