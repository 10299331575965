import { useI18n } from "@/i18n/hook";
import type { ConvoWithStats } from "@/model/ty";
import { trigger } from "@/store/store";
import * as humanize from "humanize-plus";

interface ConvoActionBarProps {
  convo: ConvoWithStats;
  hidden?: boolean;
}

export default function ConvoActionBar(props: ConvoActionBarProps) {
  const { convo } = props;
  const i18n = useI18n();

  return (
    <div hidden={props.hidden} className={"flex flex-col justify-around"}>
      <div className="border border-gray-200 block"></div>
      <div className="mt-2 text-gray-600 flex justify-around">
        <a
          onClick={(e) => {
            e.stopPropagation();
            trigger.ui.editorToggle({
              // we are replying to this convo
              parentConvo: convo,
              isOpen: true,
            });
          }}
          className={
            "select-none cursor-pointer " +
            "hover:text-green-400 flex items-center "
          }
        >
          <div className="flex items-center gap-1 font-semibold">
            <i className={"material-icons-outlined "}>reply</i>
            <span>{i18n.t("convo/reply")}</span>
            <ActionCounter count={convo.stats?.replies} />
          </div>
        </a>
        <a
          className={
            "select-none cursor-pointer font-semibold " +
            "hover:text-pink-400 hover:font-bold flex flex-align-center "
          }
          onClick={(e) => {
            e.stopPropagation();
            trigger.ui.reactionUIToggle({
              convo: props.convo,
              isOpen: true,
            });
          }}
        >
          <div className="flex items-center gap-1">
            <i className={"material-icons-outlined "}>mood</i>
            <span>{i18n.t("react")}</span>
          </div>
        </a>
      </div>
    </div>
  );
}

function ActionCounter({ count }: { count?: string }) {
  if (!count || count === "0") {
    return <></>;
  }

  const hcount = humanize.compactInteger(parseInt(count, 10));
  return (
    <span className="ml-2 text-xs p-1 rounded-full bg-gray-100">{hcount}</span>
  );
}
