import api from "@/api";
import fetcher from "@/fetcher";
import useBlurClick from "@/hook/useBlurClick";
import { useI18n } from "@/i18n/hook";
import { useSession } from "@/nextlib/context";
import React, {
  FunctionComponent as FC,
  useEffect,
  useRef,
  useState,
} from "react";
import useSWR from "swr";
import FilterSearchbar from "./FilterSearchbar";
import Loading from "./Loading";
import Persona from "./Persona";

import styles from "./PersonaSelector2.module.scss";

// TODO:
interface PersonaRow {
  persona: string;
  isVerified?: boolean;
}
interface PersonaSelector2Props {
  prompt: string;

  personas?: PersonaRow[];
  defaultPersona?: string;
  onSelect?: (persona: string) => void;
}

/**
 * PersonaSelector2 - Toolbar component that powers persona selection
 *
 * @param props {@link PersonaSelector2Props}
 * @returns
 */
const PersonaSelector2: FC<PersonaSelector2Props> = (
  props: PersonaSelector2Props
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<undefined | string>(
    props.defaultPersona
  );

  const [filtered, setFiltered] = useState<Array<string> | null>(null);
  const i18n = useI18n();
  const selectionRef = useRef(null);
  const dropdownRef = useRef(null);

  const session = useSession();
  const { data, isValidating: loadingPersona } = useSWR(
    !!session ? api.account.persona : null,
    fetcher
  );

  const isLoading = loadingPersona;
  const personas = data?.result || props.personas || [];
  const defaultPersona = selected || (personas.length && personas[0].persona);

  const selectedPersona = (personas || []).filter(
    (p: any) => p.persona === defaultPersona
  )[0];

  useEffect(() => {
    // only on initial render
    props.onSelect && props.onSelect(defaultPersona);
  }, [defaultPersona]);

  function onFilterUpdate(prefix: string) {
    if (!prefix) {
      setFiltered(personas);
    } else {
      setFiltered(personas.filter((p: any) => p.persona.startsWith(prefix)));
    }
  }

  function onSelect(persona: string) {
    setSelected(persona);
    props.onSelect && props.onSelect(persona);
    setIsOpen(false);
  }

  useBlurClick([selectionRef, dropdownRef], () => setIsOpen(false));

  if (isLoading) {
    return (
      <div className="flex flex-col p-2 border border-gray-600 rounded-t bg-gray-600 text-white">
        <div className="h-12 flex flex-align-center flex-space-between">
          <Loading></Loading>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-600 text-white rounded-t p-2">
      <div
        className={
          `h-12 flex flex-align-center flex-space-between rounded-lg border border-gray-400 hover:bg-gray-500 ` +
          (isOpen ? "bg-gray-500" : "")
        }
      >
        <button
          ref={selectionRef}
          onClick={(e) => setIsOpen(!isOpen)}
          className={"flex flex-align-center px-4 cursor-pointer "}
        >
          <span className="select-none text-xl">{props.prompt}</span>
          <div className="p-2">
            <Persona
              key={selectedPersona && selectedPersona.persona}
              noLink
              persona={selectedPersona && selectedPersona.persona}
              isVerified={selectedPersona && selectedPersona.verified}
            />
          </div>
          <span className="select-none material-icons-outlined">
            arrow_drop_down
          </span>
        </button>
        <div className="select-none">
          {/** TODO: promote button to promote post?? */}
        </div>
      </div>
      {/* selection */}
      <div ref={dropdownRef} className="relative z-10">
        <div
          hidden={!isOpen}
          className="p-2 mt-2 rounded-lg absolute border border-1 border-gray-200 bg-white w-max left-0 shadow-sm text-black"
        >
          <FilterSearchbar
            icon="person_search"
            placeholder={i18n.t("search_persona")}
            onUpdate={onFilterUpdate}
          />
          <ul className="mt-2 max-h-64 overflow-y-auto">
            {(filtered ?? personas).map((p: any, idx: number) => (
              <li
                key={idx}
                className="hover:bg-gray-100 cursor-pointer flex flex-align-center flex-space-between p-2"
              >
                <button
                  className="w-full text-left"
                  onClick={() => onSelect(p.persona)}
                >
                  <Persona
                    noLink
                    persona={p.persona}
                    isVerified={!!p.verified}
                  />
                  {false /*&& props.personasMeta && props.personasMeta[idx]*/ ? (
                    <span>
                      {i18n.t("used_times", {
                        count: 0, // props.personasMeta![idx].count,
                      })}
                    </span>
                  ) : (
                    <></>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PersonaSelector2;
