import { useI18n } from "@/i18n/hook";
import path from "@/server/path";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import styles from "./Persona.module.scss";
import { generateCssPatternClass } from "@/patternlib/pattern";

interface PersonaProps {
  persona: string;

  country?: string;
  count?: string;
  isVerified?: boolean;
  hideCountry?: boolean;
  hideCounter?: boolean;
  noLink?: boolean;

  bgColor?: string;
  borderColor?: string;

  badge?: string;
  highlight?: boolean;
  crown?: boolean;

  onClick?: (persona: string) => void;
}

export default function Persona(props: PersonaProps) {
  const router = useRouter();
  const i18n = useI18n();
  const [patternCls, setPatternCls] = useState("");

  const prefixedPersona = `${props.persona}`;

  const bgColor = props.bgColor ?? "bg-gray-600";
  const textColor = "text-gray-100 text-shadow-md";
  const borderColor = props.borderColor ?? "border-black";
  const border = borderColor === "border-white" ? "" : "border border-2";

  useEffect(() => {
    (async () => {
      setPatternCls(await generateCssPatternClass(props.persona));
    })();
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div
      className="flex flex-align-center cursor-pointer "
      onClick={() => props.onClick && props.onClick(props.persona)}
    >
      {/* show counter */}
      {!props.hideCounter && (props.count ?? false) ? (
        <div className="flex items-center gap-1 mr-1">
          <span className="md:text-lg font-semibold">{props.count}</span>
          {router.locale === "zh-TW" ? (
            <span className="">{i18n.t("person_counter")}</span>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="flex-col">
        <span
          className={`flex items-center flex-align-center px-3 md:text-lg rounded-full ${border} ${borderColor} ${patternCls} relative`}
        >
          {/* verified badge */}
          {props.isVerified ? (
            <div
              className="flex flex-align-center text-center select-none bg-blue-400 rounded-full mr-2"
              style={{ height: 18, width: 18 }}
            >
              <i
                className={`material-icons-outlined absolute ${textColor}`}
                style={{ "margin-left": "-3px" } as any}
              >
                verified
              </i>
            </div>
          ) : (
            <></>
          )}
          {props.noLink ? (
            <span className={`select-none ${textColor} font-semibold`}>
              {prefixedPersona}
            </span>
          ) : (
            <Link href={path.persona(props.persona)}>
              <a
                onClick={(e) => e.stopPropagation()}
                className={`select-none font-semibold ${textColor}`}
              >
                <span>{prefixedPersona}</span>
              </a>
            </Link>
          )}
          {props.crown ? (
            <span className="absolute top-0 right-0 transform-gpu scale-125 translate-x-2 -translate-y-2 rotate-45 select-none">
              <img src="/svg/006-crown.svg" width="16" />
            </span>
          ) : (
            <></>
          )}
          {props.highlight ? (
            <span className="absolute top-0 right-0 transform-gpu translate-x-2 -translate-y-2 select-none text-green-300">
              <i className="material-icons-outlined bg-white rounded-full">
                check_circle
              </i>
            </span>
          ) : (
            <></>
          )}
        </span>
        {false /* props.hideCountry? */ ? (
          <div className="flex">
            <span className={styles.country}>{props.country || "sky"}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

/** ignore this... */
/**
 * 
 * 
 * [
    "bg-inherit-0",
    "bg-inherit-1",
    "bg-inherit-2",
    "bg-inherit-3",
    "bg-inherit-4",
    "bg-inherit-5",
    "bg-inherit-6",
    "bg-current-0",
    "bg-current-1",
    "bg-current-2",
    "bg-current-3",
    "bg-current-4",
    "bg-current-5",
    "bg-current-6",
    "bg-current-7",
    "bg-current-8",
    "bg-current-9",
    "bg-current-10",
    "bg-current-11",
    "bg-transparent-0",
    "bg-transparent-1",
    "bg-transparent-2",
    "bg-transparent-3",
    "bg-transparent-4",
    "bg-transparent-5",
    "bg-transparent-6",
    "bg-transparent-7",
    "bg-transparent-8",
    "bg-transparent-9",
    "bg-transparent-10",
    "bg-black-0",
    "bg-black-1",
    "bg-black-2",
    "bg-black-3",
    "bg-white-0",
    "bg-white-1",
    "bg-white-2",
    "bg-white-3",
    "bg-slate-50",
    "bg-slate-100",
    "bg-slate-200",
    "bg-slate-300",
    "bg-slate-400",
    "bg-slate-500",
    "bg-slate-600",
    "bg-slate-700",
    "bg-slate-800",
    "bg-slate-900",
    "bg-slate-950",
    "bg-gray-50",
    "bg-gray-100",
    "bg-gray-200",
    "bg-gray-300",
    "bg-gray-400",
    "bg-gray-500",
    "bg-gray-600",
    "bg-gray-700",
    "bg-gray-800",
    "bg-gray-900",
    "bg-gray-950",
    "bg-zinc-50",
    "bg-zinc-100",
    "bg-zinc-200",
    "bg-zinc-300",
    "bg-zinc-400",
    "bg-zinc-500",
    "bg-zinc-600",
    "bg-zinc-700",
    "bg-zinc-800",
    "bg-zinc-900",
    "bg-zinc-950",
    "bg-neutral-50",
    "bg-neutral-100",
    "bg-neutral-200",
    "bg-neutral-300",
    "bg-neutral-400",
    "bg-neutral-500",
    "bg-neutral-600",
    "bg-neutral-700",
    "bg-neutral-800",
    "bg-neutral-900",
    "bg-neutral-950",
    "bg-stone-50",
    "bg-stone-100",
    "bg-stone-200",
    "bg-stone-300",
    "bg-stone-400",
    "bg-stone-500",
    "bg-stone-600",
    "bg-stone-700",
    "bg-stone-800",
    "bg-stone-900",
    "bg-stone-950",
    "bg-red-50",
    "bg-red-100",
    "bg-red-200",
    "bg-red-300",
    "bg-red-400",
    "bg-red-500",
    "bg-red-600",
    "bg-red-700",
    "bg-red-800",
    "bg-red-900",
    "bg-red-950",
    "bg-orange-50",
    "bg-orange-100",
    "bg-orange-200",
    "bg-orange-300",
    "bg-orange-400",
    "bg-orange-500",
    "bg-orange-600",
    "bg-orange-700",
    "bg-orange-800",
    "bg-orange-900",
    "bg-orange-950",
    "bg-amber-50",
    "bg-amber-100",
    "bg-amber-200",
    "bg-amber-300",
    "bg-amber-400",
    "bg-amber-500",
    "bg-amber-600",
    "bg-amber-700",
    "bg-amber-800",
    "bg-amber-900",
    "bg-amber-950",
    "bg-yellow-50",
    "bg-yellow-100",
    "bg-yellow-200",
    "bg-yellow-300",
    "bg-yellow-400",
    "bg-yellow-500",
    "bg-yellow-600",
    "bg-yellow-700",
    "bg-yellow-800",
    "bg-yellow-900",
    "bg-yellow-950",
    "bg-lime-50",
    "bg-lime-100",
    "bg-lime-200",
    "bg-lime-300",
    "bg-lime-400",
    "bg-lime-500",
    "bg-lime-600",
    "bg-lime-700",
    "bg-lime-800",
    "bg-lime-900",
    "bg-lime-950",
    "bg-green-50",
    "bg-green-100",
    "bg-green-200",
    "bg-green-300",
    "bg-green-400",
    "bg-green-500",
    "bg-green-600",
    "bg-green-700",
    "bg-green-800",
    "bg-green-900",
    "bg-green-950",
    "bg-emerald-50",
    "bg-emerald-100",
    "bg-emerald-200",
    "bg-emerald-300",
    "bg-emerald-400",
    "bg-emerald-500",
    "bg-emerald-600",
    "bg-emerald-700",
    "bg-emerald-800",
    "bg-emerald-900",
    "bg-emerald-950",
    "bg-teal-50",
    "bg-teal-100",
    "bg-teal-200",
    "bg-teal-300",
    "bg-teal-400",
    "bg-teal-500",
    "bg-teal-600",
    "bg-teal-700",
    "bg-teal-800",
    "bg-teal-900",
    "bg-teal-950",
    "bg-cyan-50",
    "bg-cyan-100",
    "bg-cyan-200",
    "bg-cyan-300",
    "bg-cyan-400",
    "bg-cyan-500",
    "bg-cyan-600",
    "bg-cyan-700",
    "bg-cyan-800",
    "bg-cyan-900",
    "bg-cyan-950",
    "bg-sky-50",
    "bg-sky-100",
    "bg-sky-200",
    "bg-sky-300",
    "bg-sky-400",
    "bg-sky-500",
    "bg-sky-600",
    "bg-sky-700",
    "bg-sky-800",
    "bg-sky-900",
    "bg-sky-950",
    "bg-blue-50",
    "bg-blue-100",
    "bg-blue-200",
    "bg-blue-300",
    "bg-blue-400",
    "bg-blue-500",
    "bg-blue-600",
    "bg-blue-700",
    "bg-blue-800",
    "bg-blue-900",
    "bg-blue-950",
    "bg-indigo-50",
    "bg-indigo-100",
    "bg-indigo-200",
    "bg-indigo-300",
    "bg-indigo-400",
    "bg-indigo-500",
    "bg-indigo-600",
    "bg-indigo-700",
    "bg-indigo-800",
    "bg-indigo-900",
    "bg-indigo-950",
    "bg-violet-50",
    "bg-violet-100",
    "bg-violet-200",
    "bg-violet-300",
    "bg-violet-400",
    "bg-violet-500",
    "bg-violet-600",
    "bg-violet-700",
    "bg-violet-800",
    "bg-violet-900",
    "bg-violet-950",
    "bg-purple-50",
    "bg-purple-100",
    "bg-purple-200",
    "bg-purple-300",
    "bg-purple-400",
    "bg-purple-500",
    "bg-purple-600",
    "bg-purple-700",
    "bg-purple-800",
    "bg-purple-900",
    "bg-purple-950",
    "bg-fuchsia-50",
    "bg-fuchsia-100",
    "bg-fuchsia-200",
    "bg-fuchsia-300",
    "bg-fuchsia-400",
    "bg-fuchsia-500",
    "bg-fuchsia-600",
    "bg-fuchsia-700",
    "bg-fuchsia-800",
    "bg-fuchsia-900",
    "bg-fuchsia-950",
    "bg-pink-50",
    "bg-pink-100",
    "bg-pink-200",
    "bg-pink-300",
    "bg-pink-400",
    "bg-pink-500",
    "bg-pink-600",
    "bg-pink-700",
    "bg-pink-800",
    "bg-pink-900",
    "bg-pink-950",
    "bg-rose-50",
    "bg-rose-100",
    "bg-rose-200",
    "bg-rose-300",
    "bg-rose-400",
    "bg-rose-500",
    "bg-rose-600",
    "bg-rose-700",
    "bg-rose-800",
    "bg-rose-900",
    "bg-rose-950",
    "bg-lightBlue-50",
    "bg-lightBlue-100",
    "bg-lightBlue-200",
    "bg-lightBlue-300",
    "bg-lightBlue-400",
    "bg-lightBlue-500",
    "bg-lightBlue-600",
    "bg-lightBlue-700",
    "bg-lightBlue-800",
    "bg-lightBlue-900",
    "bg-lightBlue-950",
    "bg-warmGray-50",
    "bg-warmGray-100",
    "bg-warmGray-200",
    "bg-warmGray-300",
    "bg-warmGray-400",
    "bg-warmGray-500",
    "bg-warmGray-600",
    "bg-warmGray-700",
    "bg-warmGray-800",
    "bg-warmGray-900",
    "bg-warmGray-950",
    "bg-trueGray-50",
    "bg-trueGray-100",
    "bg-trueGray-200",
    "bg-trueGray-300",
    "bg-trueGray-400",
    "bg-trueGray-500",
    "bg-trueGray-600",
    "bg-trueGray-700",
    "bg-trueGray-800",
    "bg-trueGray-900",
    "bg-trueGray-950",
    "bg-coolGray-50",
    "bg-coolGray-100",
    "bg-coolGray-200",
    "bg-coolGray-300",
    "bg-coolGray-400",
    "bg-coolGray-500",
    "bg-coolGray-600",
    "bg-coolGray-700",
    "bg-coolGray-800",
    "bg-coolGray-900",
    "bg-coolGray-950",
    "bg-blueGray-50",
    "bg-blueGray-100",
    "bg-blueGray-200",
    "bg-blueGray-300",
    "bg-blueGray-400",
    "bg-blueGray-500",
    "bg-blueGray-600",
    "bg-blueGray-700",
    "bg-blueGray-800",
    "bg-blueGray-900",
    "bg-blueGray-950"
]

 */
