import React, { FunctionComponent as FC } from "react";

import styles from "./FilterSearchbar.module.scss";

interface FilterSearchbarProps {
  placeholder?: string;
  onUpdate?: (state: string) => void;
  height?: number;
  icon?: "search" | "person_search";
}

const FilterSearchbar: FC<FilterSearchbarProps> = (
  props: FilterSearchbarProps
) => {
  return (
    <div className="p-2 border border-gray-300 flex flex-align-center h-14">
      <div className="flex flex-align-center">
        <i className="absolute pl-1 m-auto material-icons-outlined">
          {props.icon ?? "search"}
        </i>
      </div>
      <input
        placeholder={props.placeholder}
        onChange={(e) =>
          props.onUpdate && props.onUpdate((e.target as any).value)
        }
        className="focus:border-0 pl-8 text-xl focus:outline-none h-full w-full"
      />
    </div>
  );
};

export default FilterSearchbar;
