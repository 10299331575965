import api from "@/api";
import fetcher from "@/fetcher";
import { Conversation } from "@/gen/schema";
import { useSession } from "@/nextlib/context";
import { onStoreUpdate, trigger } from "@/store/store";
import type { Editor as EditorStoreTy } from "@/store/ty";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import Editor from "./Editor";
import styles from "./EditorButton.module.scss";
import Modal from "./Modal";
import QuickEditor from "./QuickEditor";

interface EditorButotnProps {}

export default function EditorButton(props: EditorButotnProps) {
  const session = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [eventId, setEventId] = useState("");
  const [fullEditorDefaultContent, setFullEditorDefaultContent] = useState("");
  const [parentConvo, setParentConvo] = useState<Conversation | undefined>(
    undefined
  );
  const { data, isValidating: loadingPersona } = useSWR(
    !!session ? api.account.persona : null,
    fetcher
  );

  // NOTE: EditorButton is the entrypoint for handling isOpen editor state
  // change - ie we check session & persona etc. to intercept isOpen change
  useEffect(() => {
    const s = onStoreUpdate("$.ui.editor", (res) => {
      const editorState = res.data[0] as EditorStoreTy;

      setIsOpen(editorState?.isOpen ?? false);
      setMode(editorState?.mode ?? "");
      setEventId(editorState?.eventId ?? "");
      setParentConvo(editorState?.parentConvo ?? undefined);
      setFullEditorDefaultContent(editorState?.fullEditorDefaultContent ?? "");
    });

    return () => {
      s.end(true);
    };
  }, []);

  const isLoading = loadingPersona;

  if (!isLoading && isOpen && !session?.email) {
    // if we do not have a valid session
    trigger.ui.accountPrompt({ isOpen: true });
    setTimeout(() => {
      trigger.ui.editorToggle({ isOpen: false });
    }, 0);
  } else if (!isLoading && isOpen && !data?.result.length) {
    /** if we do not have any personas */
    trigger.ui.personaPrompt({ isOpen: true });
    setTimeout(() => {
      trigger.ui.editorToggle({ isOpen: false });
    }, 0);
  }

  const loggedInWithPersonasAndOpen =
    !isLoading && isOpen && session?.email && data?.result.length;
  return (
    <>
      <button
        onClick={() => trigger.ui.editorToggle({ isOpen: true })}
        className={
          "bg-white cursor-pointer shadow-xl flex items-center flex-center flex-align-center z-10 select-none border border-gray-300 rounded-lg " +
          styles.checkoutBtn
        }
      >
        {loggedInWithPersonasAndOpen ? (
          <i className={"material-icons-outlined text-4xl text-slate-700 "}>
            pending
          </i>
        ) : (
          <i className={"material-icons-outlined text-4xl text-slate-700 "}>
            history_edu
          </i>
        )}
      </button>
      <Modal
        fixedAtBottom
        hidden={!loggedInWithPersonasAndOpen}
        onBgClick={() => trigger.ui.editorToggle({ isOpen: false })}
      >
        {mode === "quick" ? (
          <QuickEditor />
        ) : (
          <Editor
            eventId={eventId}
            parentConvo={parentConvo}
            defaultContent={fullEditorDefaultContent}
          />
        )}
      </Modal>
    </>
  );
}
