import React, { FunctionComponent as FC } from "react";
import styles from "./Err.module.scss";
import { useI18n } from "@/i18n/hook";

interface ErrProps {
  message?: string;
}

const Err: FC<ErrProps> = (props: ErrProps) => {
  const i18n = useI18n();
  const msg = props.message ?? i18n.t("transient_err_try_again");

  return <span>{msg}</span>;
};

export default Err;
