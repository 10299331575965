const keys = {
  account: "/account",
  signin: "/account/signin",
  account2(resource: "signin" | "convo") {
    return `/account/${resource}`;
  },

  checkEmail: `/account/signin?notifyMessage=${encodeURIComponent(
    "Email sent. Check your email"
  )}&notifyLevel=ok`,

  about: "/about",
  mailbox: "/mailbox",
  explore: "/explore",
  newPersona: "/persona",
  invalid: "/invalid",

  faq(hashLink: string) {
    return `/about#` + hashLink;
  },
  convo(id: string) {
    return `/convo/${id}`;
  },
  persona(p: string) {
    return `/persona/${p}`;
  },
  event(p: string) {
    return `/event/${p}`;
  },
  search(q: string) {
    return keys.withParams("/search", {
      q,
    });
  },

  withParams(url: string, params: Record<string, string>): string {
    const qs = Object.entries(params).reduce((acc, kv) => {
      const [k, v] = kv;
      return acc + `&${k}=${encodeURIComponent(v)}`;
    }, "");

    return url + "?" + qs.slice(1);
  },
};

export default keys;
